import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useMemo } from 'react';
import Table2 from './CTable2';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;



export default function ChangeQuestion() {
    const [rows, setRows] = useState([]);
    const [clientUid, setClientUid] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [editIdx, setEditIdx] = useState(-1);
    const [open, setOpen] = useState(false);//제출확인창
    const [openSnackbar, setOpenSnackbar] = useState(false);//수정완료창
    const navigate = useNavigate();
    const [navopen, setNavOpen] = useState(true);
    const [userData, setUserData] = useState(null);

    const sendEmails = async (body) => {
        console.log(userData);
        if (userData) {
          try {
            for (const user of userData) {
              const emailData = new URLSearchParams();
              emailData.append('from', 'FISA@stepstonekr.com');
              emailData.append('to', user.email);
              emailData.append('subject', '단계 완료 알림입니다.');
              emailData.append('body', body);
    
              await axios.post(`${API_URL}/api/email/send`, emailData, { 
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              });
            }
            console.log('Emails sent successfully');
          } catch (error) {
            console.error('Error sending emails:', error);
          }
        }
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientId = localStorage.getItem('clientId');//현재 보고자하는 uid로 변경해줄것!!!
                const uid = await getUidById(clientId);
                setClientUid(uid)


                const [response, answerResponse] = await Promise.all([
                    fetch(`${API_URL}/api/questions`),
                    fetch(`${API_URL}/api/answers/user/uid=${uid}`)
                ]);
                const data = await response.json();
                const savedAnswer = await answerResponse.json();
                console.log("savedAnswer", savedAnswer);

                const businessType = await getBusinessType(uid);
                const category = "사업아이디어진단(" + businessType + ")";
                setBusinessType(category);

                const engageresponse = await axios.get(`${API_URL}/consultant/getConsultEngagement/clientUid=${uid}`);
        console.log(engageresponse);

        // Assuming response.data contains an array of consultants
        const clientIds = new Set(engageresponse.data.map(item => item.clientId));

        const userdata = await fetch(`${API_URL}/consultant/getUserList`);
        const engagedata = await userdata.json();
        console.log(engagedata);

        // Filter to keep only users with ids in the consultantIds set
        const filteredUsers = engagedata.filter(user => clientIds.has(user.id));
        setUserData(filteredUsers);

                console.log(category)
                const filtered = data.filter(question => question.questionType === category);

                const questionsWithSavedAnswer = filtered.map((question, index) => { // 답변이 있으면 답변도 저장해놓음 update시 null로 초기화 방지
                    const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
                    if (matchingAnswer) {
                        return {
                            ...question,
                            id: index + 1,
                            answerText: matchingAnswer.answerText,
                            asIs: matchingAnswer.asIs,
                            keyword: matchingAnswer.keyword,
                            modifiedQuestion: matchingAnswer.modifiedQuestion
                        };
                    } else {
                        // savedAnswer에 해당 questionId와 일치하는 데이터가 없는 경우
                        return {
                            ...question,
                            id: index + 1,
                        };
                    }
                });
                // 병합된 행을 처리하기 위한 로직
                const mergedRows = questionsWithSavedAnswer.reduce((acc, item, index, array) => {
                    // 첫 번째 행이거나 이전 행과 'questionClass' 값이 다른 경우
                    if (index === 0 || item.questionClass !== array[index - 1].questionClass) {
                        // 'rowspan' 값을 계산
                        let rowspan = 1;
                        for (let i = index + 1; i < array.length; i++) {
                            if (array[i].questionClass === item.questionClass) {
                                rowspan++;
                            } else {
                                break;
                            }
                        }
                        acc.push({ ...item, rowspan: rowspan });
                    } else {
                        // 이전 행과 'questionClass' 값이 같으면 생략 (병합된 행의 일부)
                        acc.push({ ...item, isMerged: true });
                    }
                    return acc;
                }, []);
                console.log("merged", mergedRows)
                setRows(mergedRows);
                console.log("data", questionsWithSavedAnswer);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const getBusinessType = async (uid) => {
        try {
            const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
                params: {
                    uid: uid
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data);
            return (response.data);
        } catch (error) {
            // Handle errors
            console.error('Error getting businessType:', error);
        }
    };
    const getUidById = async (clientId) => {
        try {
            const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const clientUid = await clientUidResponse.text();
            return clientUid;
        }
        catch (error) {
            console.error('Error getting clientId:', error);
        }
    }

    const sendUpdateToServer = async (updatedRow) => {

        try {
            const updateData = { ...updatedRow, uid: clientUid };

            // uid 속성 추가
            console.log("받은인자", updatedRow);
            console.log("updateData", updateData);


            await fetch(`${API_URL}/api/answers/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            console.log('updated successfully')
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleEdit = (idx) => {
        setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
    };

    const handleSave = async (idx) => {
        setEditIdx(-1); // 편집 모드를 종료합니다.
        await sendUpdateToServer(rows[idx]); // 변경된 행을 서버로 전송합니다.
        setOpenSnackbar(true);
    };

    const handleChange = (e, idx, column) => {
        const updatedData = rows.map((row, index) =>
            index === idx ? { ...row, [column]: e.target.value } : row
        );
        setRows(updatedData); // 키워드 변경사항을 상태에 반영합니다.
    };

    const updateRowsToServer = async () => {
        for (const data of rows) {
            await sendUpdateToServer(data); // 비동기 함수 호출 및 완료까지 대기
        }
    };

    const updateUserStatus = async (updateValue) => {
        try {
            const response = await axios.post(`${API_URL}/auth/setStatus`, {
                uid: clientUid,
                status: updateValue,
            });
            console.log("Client Status updated successfully");
            return response.data;
        } catch (error) {
            // 요청이 실패하면 에러를 반환
            throw error;
        }
    };

    const ConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
            await onConfirm(); // 실제 작업 수행
            await updateUserStatus(3);
            sendEmails('고객님의 사업아이디어 질문이 작성되었습니다. 확인 후 답변해 주십시오. <br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.')
            setTimeout(() => {
                navigate("/ConsultantFile");
                setConfirming(false);
            }, 2000); // 2초 후에 작업이 실행됩니다.
        };
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>작성하신 내용으로 질문을 수정하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">수정한 내용으로 사업아이디어 진단이 진행됩니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const toggleDrawer = () => {
        setNavOpen(!navopen);
    };

    const defaultTheme = createTheme();


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navbar open={navopen} toggleDrawer={toggleDrawer} />
            <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} />
            <Box sx={{ height: "100%", marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 20, backgroundColor: 'white' }}>
                {/* <Table2/> */}
                <h2>{businessType} 질문 수정하기</h2>
                <Alert severity="info">수정하지 않을 거면  빈칸으로 비워두십시오. <br/>추가로 원하지 않는 질문일 경우 "내용 없음" 이라고 입력해 주십시오.</Alert>
                <table className="MyTable">
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>질문</th>
                            <th>수정할 질문</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.slice(0, 19).map((item, idx) => {
                            // 병합되어야 할 셀인지 확인
                            const isMergedCell = item.rowspan >= 1;
                            return (
                                <tr key={idx}>
                                    {isMergedCell && (
                                        <td rowSpan={item.rowspan || 1}>{item.questionClass}</td>
                                    )}
                                    <td>{item.question}</td>
                                    <td onClick={item.questionClass !== 'Background' ? () => handleEdit(idx) : null} 
                                         style={{ position: 'relative' }} >
                                        {editIdx === idx ? (

                                            <Textarea
                                                value={item.modifiedQuestion}
                                                onChange={(e) => handleChange(e, idx, "modifiedQuestion")}
                                                // onBlur={() => handleSave(idx)}
                                                style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                                            />

                                        ) : (
                                            <span>{item.modifiedQuestion}</span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <Fab color="secondary"
                    varient="extended"
                    onClick={() => setOpen(true)}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 30,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>제출</span>
                    <RightArrow />
                </Fab>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}>
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        수정사항이 저장되었습니다!
                    </Alert>
                </Snackbar>
                <ConfirmDialog onClose={() => setOpen(false)} onConfirm={updateRowsToServer} />

            </Box>
        </Box>
    );
};
