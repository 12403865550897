import React, { useState, useEffect } from "react";
import { useRef } from "react";

const Chat = ({ currentQuestion, onAnswerSubmit, setNextQuestion, isQASessionOver, step }) => {
  const [messages, setMessages] = useState([
    { text: "안녕하세요, 다음의 질문을 대답해주십시오", isUser: false, step: step }
  ]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [currentTypingId, setCurrentTypingId] = useState(null);
  const messagesEndRef = useRef(null);

  const handleSendMessage = (message) => {
    // Handling an answer to a question
    if (currentQuestion && message !== currentQuestion.question) {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: message, isUser: true, step: step }
      ]);
      onAnswerSubmit({ ...currentQuestion, answer: message });
      setNextQuestion(); // Move to the next question
    } else {
      // Handling a regular message (not an answer to a question)
      setMessages(prevMessages => [
        ...prevMessages,
        { text: message, isUser: true },
        {
          text: `입력: "${message}"`,
          isUser: false,
          isTyping: true,
          id: Date.now(),
        }
      ]);
    }
  };

  const handleButtonClick = (message) => {
    handleSendMessage(message);
  };

  useEffect(() => {
    const questionAlreadyAdded = messages.some(message => message.text === currentQuestion.question);

    if (!questionAlreadyAdded) {
      // Set a timeout to delay the addition of the question
      const timer = setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: currentQuestion.question, isUser: false, step: step }
        ]);
      }, 0); // Delay of 1 second

      return () => clearTimeout(timer); // Cleanup the timer
    }

  }, [currentQuestion, messages]);

  useEffect(() => {
    const completionMessage = "모든 답변을 완료했습니다. 답변한 내용이 맞는지 확인해주시고 수정사항이 있으면 수정한 후 제출해주세요!";

    if (isQASessionOver) {
      // 중복을 확인하기 위한 변수
      let isMessageDuplicate = false;
  
      // 이전 메시지와 새 메시지를 비교하여 중복 여부 확인
      messages.forEach(message => {
        if (message.text === completionMessage && message.step === step) {
          isMessageDuplicate = true;
        }
      });
  
      // 중복되지 않은 경우에만 새 메시지 추가
      if (!isMessageDuplicate) {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: completionMessage, isUser: false, step: step }
        ]);
      }
    }
  }, [isQASessionOver]);

  return (
    <div className="app">
      <div className="chat-box">
        <h1>🤖 Chat Bot</h1>
        <MessageList
          messages={messages}
          currentTypingId={currentTypingId}
          step={step}
          style={{
            marginBottom: '500px' // Adjust the value as needed to create space for the button group
          }}
        />
        <div className="button-group" style={{
        width: 'calc(100% - 50px)',
        bottom: '10px',  // Example placement, adjust as needed
        left: '20px'   // Example placement, adjust as needed
      }}>
        <button style={{width: 'calc(50% - 30px)',}}
        onClick={() => handleButtonClick("예")}
        disabled={isQASessionOver}>예</button>
        <button style={{width: 'calc(50% - 30px)',}}
        onClick={() => handleButtonClick("아니오")}
        disabled={isQASessionOver}>아니오</button>
      </div>
      </div>
    </div>
  );
};

const MessageList = ({ messages, currentTypingId, step }) => {
  const messagesEndRef = useRef(null);
  const filteredMessages = messages.filter(message => message.type === step);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [filteredMessages]);

  return (
    <div className="messages-list">
      {filteredMessages.map((message, index) => (
        <Message
          key={index}
          {...message}
        />
      ))}
      {/* Invisible element at the end of the messages */}
      <div ref={messagesEndRef} />
    </div>
  );
};


const Message = ({
  text,
  isUser,
  isTyping,
  id,
  onEndTyping,
  currentTypingId,
  type,
}) => {
  return (
    <div className={isUser ? "user-message" : "ai-message"}>
      {isTyping && currentTypingId === id ? (
        <p>
          <b>Step Bot</b>: {text}
        </p>
      ) : (
        <p>
          <b>{isUser ? "User" : "Step Bot"}</b>: {text}
        </p>
      )}
    </div>
  );
};

export default Chat;
