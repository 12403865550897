import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Window from '../../Customer/Window/Window';
import FactManual from '../FactManual';
import ChatwithGpt from '../ChatwithGpt';
import InsightManual from '../InsightManual';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerComponent = ({ open, toggleDrawer }) => {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [windowOpen, setWindowOpen] = useState(false);
  const [gptWindowOpen, setGptWindowOpen] = useState(false);
  const [insightWindowOpen, setInsightWindowOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleMenuItemClick = (item) => {
    if (item === 'manual') {
      setWindowOpen(!windowOpen);
    }
    if (item === 'gpt') {
      setGptWindowOpen(!gptWindowOpen);
    }
    if (item === 'insight') {
      setInsightWindowOpen(!insightWindowOpen);
    }
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1] }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton onClick={() => handleNavigation('/ConsultantMypage')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="내 정보" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigation('/ConsultantUsers')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="고객 정보" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigation('/ConsultantConsulting')}>
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary="컨설팅 목록" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigation('/ConsultantQuestions')}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="질문지" />
          </ListItemButton>
        </List>
        <Divider />
        <List component="nav">
          <ListSubheader component="div" inset>
            추가 기능
          </ListSubheader>
          <ListItemButton onClick={() => handleMenuItemClick('manual')}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="TAM/SAM 도출 메뉴얼" />
          </ListItemButton>
          <ListItemButton onClick={() => handleMenuItemClick('insight')}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="SOM 도출 메뉴얼" />
          </ListItemButton>
          <ListItemButton onClick={() => handleMenuItemClick('gpt')}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="챗 GPT" />
          </ListItemButton>
        </List>
      </Drawer>
      {windowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "TAM/SAM 도출 프롬프트 메뉴얼",
            buttons: { minimize: true, maximize: true, close: () => setWindowOpen(!windowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <FactManual />
        </Window>
      )}
      {gptWindowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "Chat Gpt",
            buttons: { minimize: true, maximize: true, close: () => setGptWindowOpen(!gptWindowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <ChatwithGpt />
        </Window>
      )}
      {insightWindowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "SOM 도출 프롬프트 메뉴얼",
            buttons: { minimize: true, maximize: true, close: () => setInsightWindowOpen(!insightWindowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <InsightManual />
        </Window>
      )}
    </>
  );
};

export default DrawerComponent;
