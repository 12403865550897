// Initial.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from '@mui/material';

const factImage = require('./images/fact1.png');
const insightImage = require('./images/insight2.png');
const strategyImage = require('./images/strategy1.png');
const actionImage = require('./images/action1.png');

const styles = {
  initialContainer: {
    textAlign: 'center', // 가운데 정렬을 위한 스타일
  },
  moduleContainer: {
    display: 'flex',
    justifyContent: 'space-between', // 가로로 펼치기 위한 스타일
  },
  module: {
    width: '23%', // 각 모듈이 차지할 너비를 조절
    margin: '10px', // 각 모듈 간의 간격을 조절
  },
  image: {
    width: '100%', // 이미지 너비 조절
    height: 'auto', // 이미지 높이 자동 조절
  },
};

const Initial = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/signin');
  };

  return (
    <div style={styles.initialContainer}>
      <h1>SteppingStone Startup 진단 Tool</h1>
      <hr />

      <div>
        <h2>SteppingStone Startup 진단 Tool 이란?</h2>
        <p>
          FACT - INSIGHT - STRATEGY - ACTION의 4개의 모듈로 startup의 시장성을 진단하여 GTM 전략을 세우고 그를 바탕으로 실행 과제 및 계획을 수립하는 방법론입니다.
        </p>
      </div>

      <div style={styles.moduleContainer}>
        <div style={styles.module}>
          <h2>Fact</h2>
          <img src={factImage} alt="Fact" style={styles.image} />
          <p><br/><br/><br/>나의 아이디어가 사업으로서 <br />얼마나 성장 가능성이 있을까?</p>
        </div>
        <div style={styles.module}>
          <h2>Insight</h2>
          <img src={insightImage} alt="Insight" style={styles.image} />
          <p><br/><br/><br/>사업 런칭 후 1~2년 이내에 <br />사업 성과를 내기 가장 적합한 시장은 어디일까?</p>
        </div>
        <div style={styles.module}>
          <h2>Strategy</h2>
          <img src={strategyImage} alt="Strategy" style={styles.image} />
          <p><br/><br/><br/>초기 거점시장에서 의미 있는 성과를 <br />내기 위해 어떤 시장 진입 전략이 필요할까?</p>
        </div>
        <div style={styles.module}>
          <h2>Action</h2>
          <img src={actionImage} alt="Action" style={styles.image} />
          <p><br/><br/><br/>시장 진입/성장을 위해 구체적으로 <br />무엇을 어떻게 실행해야 할까?</p>
        </div>
      </div>

      <Button style={{width:300}} variant='contained' onClick={handleClick}>시작하기</Button>
    </div>
  );
};

export default Initial;
