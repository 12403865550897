import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const ProgressConsultant = () => {
    const clientId = localStorage.getItem('clientId');
    const [open, setOpen] = useState(true);
    const [openconfirmfact, setOpenConfirmFact] = useState(false);
    const [openconfirminsight, setOpenConfirmInsight] = useState(false);
    const [openconfirmstrategy, setOpenConfirmStrategy] = useState(false);
    const {status} = useParams();
    const [uid, setUid] = useState('');
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [userData, setUserData] = useState(null);
    const theme = useTheme();

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
        window.location.reload(); // 확인 버튼을 클릭하면 페이지 리로드
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleDialog = (title, message, isError = false) => {
        setDialogTitle(title);
        setDialogMessage(message);
        if (isError) {
            setOpenErrorDialog(true);
        } else {
            setOpenSuccessDialog(true);
        }
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };


    const FactConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true);
            await onConfirm();
            setOpenConfirmFact(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirmfact} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const InsightConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true);
            await onConfirm();
            setOpenConfirmInsight(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirminsight} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const StrategyConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true);
            await onConfirm();
            setOpenConfirmStrategy(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirmstrategy} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const routes = [
        { path: `/FactFile/${uid}`, label: <p>Fact 보고서 <br /> 확인하기</p>, status: 11 },
        { path: `/InsightFile/${uid}`, label: <p>Insight 보고서 <br /> 확인하기</p>, status: 21 },
        { path: '/StrategyDetail', label: <p>Strategy 보고서 <br /> 확인하기</p>, status: 31 },
        { path: '/ActionDetail', label: <p>Action 보고서 <br /> 확인하기</p>, status: 41 }
    ];

    const defaultTheme = createTheme();

    console.log("status", status);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 그리드 1: Fact */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 2 ? (status < 4 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 2 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 2}
                                >
                                    시장유형 진단하기 <br /> 질문 수정하기
                                </Button>

                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 4 ? (status < 5 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 4 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 4} 
                                >
                                    사업아이디어 진단<br />
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px', width: '100%',
                                        backgroundColor: status >= 5 ? (status < 6 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 5 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 5} 
                                >
                                    Fact 보고서 <br /> 작성하기
                                </Button>
                            </Grid>
                            {/* 그리드 2: Insight */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 15 ? (status < 16 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 15 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 15}
                                >
                                    SOM 후보 도출 <br />
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 16 ? (status < 17 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 16 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 16} 
                                >
                                    Insight 보고서 <br />작성하기
                                </Button>
                            </Grid>
                            {/* 그리드 3: Strategy */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 21 ? (status < 23 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 21 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 21} 
                                >
                                    SOM 후보 평가 <br />최종 SOM 구체화
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 23 ? (status < 29 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 23 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 23} 
                                >
                                    GTM 전략 <br />
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 29  ? (status < 999 ?theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 29 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 29} 
                                >
                                    Strategy 보고서 <br />작성하기
                                </Button>
                            </Grid>
                            {/* 그리드 4: Action */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{ width: '100%' }}
                                    disabled={status < 39} 
                                >
                                    Action 보고서 <br />작성하기
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccessDialog} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorDialog} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default ProgressConsultant;
