import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const FisaTool = ({ page }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const uid = localStorage.getItem('uid'); // 로컬 스토리지에서 uid 가져오기
    if (!uid) {
      console.error('No uid found in local storage');
      return;
    }

    const fetchPdf = async () => {
      try {
        const response = await Axios.get(`${API_URL}/file/download/fisa_tool.pdf`, {
          responseType: 'blob', // 요청의 응답 형식을 blob으로 설정
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob); // PDF 파일의 URL 생성
        setPdfUrl(`${pdfUrl}#page=${page}`); // PDF URL에 페이지 번호 추가
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [page]); // page prop이 변경될 때마다 실행

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: '100%', height: '100vh', border: 'none' }}
          ></iframe>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FisaTool;
