import React, { useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;


const AskGpt = () => {
  const [inputText, setInputText] = useState(''); // State to hold the input text
  const [previousChat, setPreviousChat] = useState(''); // State to hold previous chat messages

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [open, setOpen] = React.useState(true);
  
  const defaultTheme = createTheme();

  // Method to handle input change
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    console.log('Sending input to the backend:', inputText);

    setPreviousChat(previousChat += 'Role:user, Content:' + inputText + '\n');
  
    // Simulate sending data to the backend
    fetch(`${API_URL}/ask-chatgpt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive',
      },
      body: JSON.stringify({ prompt: previousChat +inputText }),
      credentials: 'include', // Ensure cookies are sent with the request
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      // Display the response to the user
      setPreviousChat(previousChat += 'Role:assistant, Content:' + data.choices[0].message.content + '\n');
      alert(data.choices[0].message.content);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  
    // Reset input field after submission
    setInputText('');
  };
  

  return (

    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
    <div>
      <h1>ChatGPT API 테스트</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Type your question"
        />
        <button type="submit">Submit</button>
      </form>
    </div>
    </Box>
    </Box>
    </ThemeProvider>

  );
};

export default AskGpt;