import React, { useState, useEffect } from "react";
import { useRef } from "react";
import '../../styles/chatbot.css';


const Chat = ({ currentQuestion, onAnswerSubmit, setNextQuestion, isQASessionOver, step, buttonType}) => {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [currentTypingId, setCurrentTypingId] = useState(null);
  const messagesEndRef = useRef(null);
  const infoMessage = ["고객님께서 하고자 하는 사업이 어떤 고객과 시장을 대상으로 하고 무었을 어떻게 제공할 것인지 그리고 어떻게 수익을 창출할 것인지에 대해서 알고자 합니다. 먼저 어떤 고객과 시장을 대상으로 하는지 알고자 합니다.",
                       "다음으로 목표 고객/시장에 무엇(제품/서비스)을 제공할 것인지에 대해서 알고자 합니다.",
                      "마지막으로 목표 고객/시장에 어떻게 제공하고 수익을 창출할 것인지에 대해서 알고자 합니다.",
                    "마지막으로 목표 고객/시장에 어떻게 제공하고 수익을 창출할 것인지에 대해서 알고자 합니다."];

  // 초기 메시지를 설정하기 위해 useEffect 사용
  useEffect(() => {
    setMessages([
      { text: infoMessage[step], isUser: false, step: step }
    ]);
  }, [step]);

  const handleSendMessage = (message) => {
    // Handling an answer to a question
    if (currentQuestion && message !== currentQuestion.question) {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: message, isUser: true, step: step }
      ]);
      onAnswerSubmit({ ...currentQuestion, answer: message });
      setNextQuestion(); // Move to the next question
    } else {
      // Handling a regular message (not an answer to a question)
      setMessages(prevMessages => [
        ...prevMessages,
        { text: message, isUser: true },
        {
          text: `입력: "${message}"`,
          isUser: false,
          isTyping: true,
          id: Date.now(),
        }
      ]);
    }
  };
  console.log("messages", messages);
  const handleEndTyping = (id) => {
    console.log("Ending typing for message ID:", id);
    setMessages(prevMessages =>
      prevMessages.map(msg => {
        if (msg.id === id) {
          console.log("Updating message:", msg);
          return { ...msg, isTyping: false };
        }
        return msg;
      })
    );
    setCurrentTypingId(null);
  };

  const handleButtonClick = (message) => {
    handleSendMessage(message);
  };

  useEffect(() => {
    const questionAlreadyAdded = messages.some(message => message.text === currentQuestion.question);
    
    if (!questionAlreadyAdded && !isQASessionOver) {
      // Set a timeout to delay the addition of the question
      const timer = setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: currentQuestion.question, isUser: false , step: step}
        ]);
      }, 0); // Delay of 1 second

      return () => clearTimeout(timer); // Cleanup the timer

    }

  }, [currentQuestion, messages]);

  const [showInputField, setShowInputField] = useState(true);
  const [showSendButton, setShowSendButton] = useState(true);

  useEffect(() => {
    const completionMessage = "화면에서 답변한 내용이 맞는지 확인해주시고 수정사항이 있으면 수정한 후 제출해주십시오";

    if (isQASessionOver) {
      setShowInputField(false);
      setShowSendButton(false);
      // 중복을 확인하기 위한 변수
      let isMessageDuplicate = false;
  
      // 이전 메시지와 새 메시지를 비교하여 중복 여부 확인
      messages.forEach(message => {
        if (message.text === completionMessage && message.step === step) {
          isMessageDuplicate = true;
          console.log("duplicate")
        }
      });
  
      // 중복되지 않은 경우에만 새 메시지 추가
      if (!isMessageDuplicate) {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: completionMessage, isUser: false, step: step }
        ]);
      }
    }
  }, [isQASessionOver]);



  return (
    <div className="app">
      <div className="chat-box">
      <h1 style={{marginLeft:'20px'}}>🤖 Step Bot</h1>
        {buttonType ? (
          <>
            <MessageList
              messages={messages}
              currentTypingId={currentTypingId}
              step={step}
              style={{
                marginBottom: '500px' // Adjust the value as needed to create space for the button group
              }}
            />
            <div className="button-group" style={{
              width: 'calc(100% - 50px)',
              bottom: '10px',  // Example placement, adjust as needed
              left: '20px'   // Example placement, adjust as needed
            }}>
              <button style={{width: 'calc(50% - 30px)'}}
                onClick={() => handleButtonClick("예")}
                disabled={isQASessionOver}>예</button>
              <button style={{width: 'calc(50% - 30px)'}}
                onClick={() => handleButtonClick("아니오")}
                disabled={isQASessionOver}>아니오</button>
            </div>
          </>
        ) : (
          <>
            <MessageList
              messages={messages}
              currentTypingId={currentTypingId}
              onEndTyping={handleEndTyping}
              step={step}
            />
            {<MessageForm onSendMessage={handleSendMessage} isQASessionOver={isQASessionOver}/>}
          </>
        )}

      </div>
    </div>
  );
};

const MessageList = ({ messages, currentTypingId, onEndTyping, step }) => {
  const messagesEndRef = useRef(null);
  const filteredMessages = messages.filter(message => message.step === step);

  // Scroll to bottom whenever the messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [filteredMessages]);

  return (
    <div className="messages-list">
      {filteredMessages.map((message, index) => (
        <Message
          key={index}
          {...message}
          onEndTyping={onEndTyping}
          currentTypingId={currentTypingId}
        />
      ))}
      <div ref={messagesEndRef} /> {/* Invisible element to scroll to */}
    </div>
  );
};


const Message = ({
  text,
  isUser,
  isTyping,
  id,
  onEndTyping,
  currentTypingId,
  type,
}) => {
  return (
  //   <div className={isUser ? "user-message" : "ai-message"}>
  //   <p>{text}</p>
  // </div>
    <div className={isUser ? "user-message" : "ai-message"}>
      {isTyping && currentTypingId === id ? (
        <p>
          <b>Step Bot</b>: {text}
        </p>
      ) : (
        <p>
          <b>{isUser ? "User" : "Step Bot"}</b>: {text}
        </p>
      )}
    </div>
  );
};



const MessageForm = ({ onSendMessage, currentQuestion,isQASessionOver }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (currentQuestion && currentQuestion.answer === "") {
      setMessage(currentQuestion.question);
    }
  }, [currentQuestion]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentQuestion && message !== currentQuestion.question) {
      onSendMessage(message, currentQuestion);
    } else {
      onSendMessage(message);
    }
    setMessage("");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Enter 키의 기본 동작(줄바꿈)을 방지
      handleSubmit(event); // 메시지 전송
    }
  };

  return (
    <form onSubmit={handleSubmit} className="message-form" style={{ display: 'flex', alignItems: 'center', gap: '1%' }}>
      <textarea
        type="text"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        onKeyDown={handleKeyDown}
        className="message-input"
        style={{
          width: '80%',
          minHeight: '60px', // 최소 높이
          overflowY: 'hidden' // 스크롤바 숨기기
        }}
        disabled={isQASessionOver}/>
      <button type="submit" className="send-button"
        style={{
          marginLeft: '1%',
          width: '10%',
          height: '50px',

        }}
        disabled={!message.trim() || isQASessionOver}>
        Send
      </button>
    </form>
  );
};

export default Chat;
