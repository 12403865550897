import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
const emblem = require('../main/images/initial.png');
const API_URL = process.env.REACT_APP_API_ORIGIN;

const defaultTheme = createTheme();

export default function Email() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate('/SignIn'); // 확인 버튼을 클릭하면 페이지 이동
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleDialog = (title, message, isError = false) => {
    setDialogTitle(title);
    setDialogMessage(message);
    if (isError) {
      setOpenErrorDialog(true);
    } else {
      setOpenSuccessDialog(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const user = {
      confirmationCode: data.get('certification'),
      username: state && state.name,
      email: state && state.email,
      usertype: state && state.usertype,
      phone_number: state && state.phone_number,
      program: state && state.program,
      nickname: state && state.nickname,
      usersub: state && state.usersub,
    };

    console.log(user);

    try {
      const response = await Axios.post(`${API_URL}/auth/confirm`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data);
      handleDialog('인증 성공', '회원가입이 완료되었습니다.');
    } catch (error) {
      console.error('Error submitting form:', error);
      handleDialog('인증 실패', '인증코드를 확인해주십시오', true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <img src={emblem} alt="Emblem" style={{ maxWidth: '80%', height: 'auto' }} />
          <Typography variant="body1" gutterBottom>
            <br />입력하신 이메일로 인증번호를 발송하였습니다. <br />인증번호를 입력해 주십시오.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="certification"
              label="인증번호"
              type="certification"
              id="certification"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              인증 확인
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
