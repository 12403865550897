import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function AdminUser() {
  const [open, setOpen] = React.useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openUserTypeDialog, setOpenUserTypeDialog] = React.useState(false);
  const [openUserDialog, setOpenUserDialog] = React.useState(false);
  const [userData, setUserData] = useState(null);
  const [consultantList,setConsultantList] = useState([]);
  const [clientList,setClientList] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        const consultantData = [];
        const clientData = [];
        let cnt1=1;
        let cnt2=1;

        for (const item of data) {
          if (item.user_type === 'consultant') {
            item.id = cnt1;
            consultantData.push(item);
            cnt1+=1;
          }
          else if(item.user_type === 'client'){
            item.id = cnt2;
            clientData.push(item);
            cnt2+=1;
          }
        }
        console.log("cs",consultantData);
        setConsultantList(consultantData);
        setClientList(clientData);
        console.log(data);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // 빈 배열을 두번째 매개변수로 전달하여 컴포넌트가 마운트될 때만 실행

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = createTheme();

  const consultantColumns = [
    { field: 'id', headerName: 'Index', width: 90},
    {
      field: 'nickname',
      headerName: '성명',
      width: 200,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'ID',
      width: 180,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'phone_number',
      headerName: '연락처',
      width: 200,
      editable: false,
    },
    {
      field: 'actions',
      headerName: '관리',
      width: 200,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined" startIcon={<DeleteIcon />} style={{ marginRight: '250px', color: '#D74242', borderColor: '#D74242'}}
            onClick={() => handleDeleteClick(params.row.username)}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];
  
  const clientColumns = [
    { field: 'id', headerName: 'Index', width: 90},
    {
      field: 'nickname',
      headerName: '성명',
      width: 200,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'ID',
      width: 180,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'phone_number',
      headerName: '연락처',
      width: 200,
      editable: false,
    },
    {
      field: 'actions',
      headerName: '관리',
      width: 200,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '5px' }}
            startIcon={<EditIcon />}
            onClick={() => handleEditClick(params.row.username)}
          >
            변경
          </Button>
          <Button
            variant="outlined" startIcon={<DeleteIcon />} style={{ marginRight: '250px', color: '#D74242', borderColor: '#D74242'}}
            onClick={() => handleDeleteClick(params.row.username)}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];
  
  const handleEditClick = (username) => {
    setSelectedUsername(username);
    setOpenUserTypeDialog(true);
  };
  const handleDeleteClick = (username) => {
    setSelectedUsername(username);
    setOpenDeleteDialog(true);
  };
  const deleteRequestToServer = async (username) => {
      try {
        const response = await axios.delete(`${API_URL}/auth/user/${username}`);
        console.log('User deleted successfully:', response.data);
        setConsultantList(prev => prev.filter(user => user.username !== username));
        setClientList(prev => prev.filter(user => user.username !== username));
    
        return response.data; // 서버로부터 받은 응답 반환
      } catch (error) {
        console.error('Failed to delete user:', error.response ? error.response.data : error.message);
        throw error; 
      }
  }

  const updateUserTypeToServer = async (username) => {
    try {
      const response = await axios.put(`${API_URL}/auth/userType/${username}`,  { usertype: "consultant" });
      console.log('UserType updated successfully:', response.data);
      setClientList(prev => {
        // username과 일치하는 user 객체를 찾습니다.
        const foundUser = prev.find(user => user.username === username);
        
        // username과 일치하는 user를 제외한 새 배열을 생성합니다.
        const newClientList = prev.filter(user => user.username !== username);

        // foundUser가 존재하면 consultantList에 추가합니다.
        if (foundUser) {
          setConsultantList(prevConsultants => [...prevConsultants, foundUser]);
        }

        return newClientList;
      });
      return response.data; // 서버로부터 받은 응답 반환
    } catch (error) {
      console.error('Failed to delete user:', error.response ? error.response.data : error.message);
      throw error; 
    }
}

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const ConfirmUserTypeDialog = ({ onClose, onConfirm, username }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
      try {
        await onConfirm(username); // 사용자 이름을 onConfirm 함수에 전달
        
        handleSnackbarOpen(`${username} 유저 타입이 변경되었습니다.`);
      } catch (error) {
        handleSnackbarOpen(`유저 타입 변경이 실패했습니다: ${error.message}`);
      }
      setConfirming(false);
      setOpenUserTypeDialog(false);
      
    };
    return (
      <Dialog open={openUserTypeDialog} onClose={onClose}>
        <DialogTitle>{`${username}을 Consultant로 변경하시겠습니까?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning">변경시 Client로 다시 변경할 수 없습니다.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  const ConfirmDialog = ({ onClose, onConfirm, username }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
      try {
        await onConfirm(username); // 사용자 이름을 onConfirm 함수에 전달
        
        handleSnackbarOpen(`${username} 사용자가 성공적으로 삭제되었습니다.`);
      } catch (error) {
        handleSnackbarOpen(`사용자 삭제에 실패했습니다: ${error.message}`);
      }
      setConfirming(false);
      setOpenDeleteDialog(false);
      
    };
    return (
      <Dialog open={openDeleteDialog} onClose={onClose}>
        <DialogTitle>{`정말로 ${username}을 삭제하시겠습니까?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="error">유저가 삭제되며 복구가 불가능합니다.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              {/* 컨설턴트 그리드 */}
              <Grid item xs={6} >
              <Typography variant="h5" sx={{ mb: 2 }}>
                  컨설턴트
              </Typography>
              {consultantList.length === 0 ? (
                <p>현재 컨설턴트가 없습니다.</p>
              ) : (
                <Box sx={{  backgroundColor: 'white' ,height: 400, width: '100%', margin: 'auto' }}>
                  <DataGrid
                    rows={consultantList}
                    columns={consultantColumns}
                    pageSize={100}
                    disableRowSelectionOnClick
                  />
                </Box>
              )}
              </Grid>

              <Grid item xs={6} sx ={{marginTop: 5}}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  고객
                </Typography>
                {clientList.length === 0 ? (
                <p>현재 고객이 없습니다.</p>
              ) : (
                <Box sx={{ backgroundColor:'white', height: 400, width: '100%', margin: 'auto' }}>
                  <DataGrid
                    rows={clientList}
                    columns={clientColumns}
                    pageSize={100}
                    disableRowSelectionOnClick
                  />
                </Box>
              )}
                
              </Grid>
          </Container>
          <ConfirmDialog 
            onClose={() => setOpenDeleteDialog(false)} 
            onConfirm={deleteRequestToServer}
            username = {selectedUsername} 
          />
          <ConfirmUserTypeDialog
             onClose={() => setOpenUserTypeDialog(false)} 
             onConfirm={updateUserTypeToServer}
             username = {selectedUsername} 
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >{snackbarMessage}</Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
