import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function ConsultingEdit() {
  const [open, setOpen] = React.useState(true);
  const [consultantData, setConsultantData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const {state} = location;
  const consulting = state.consulting;
  const consultantid = consulting.consultantId;
  const clientid = consulting.clientId;

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        console.log(data);

        const consultantData1 = Array.from(data).filter((user) => user.id === consultantid);
        setConsultantData(consultantData1);

        const clientData1 = data.filter((user) => user.id === clientid);
        setClientData(clientData1);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // 빈 배열을 두번째 매개변수로 전달하여 컴포넌트가 마운트될 때만 실행

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const defaultTheme = createTheme();

  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const handleConsultantSelect = (status) => {
    navigate(`/progressconsultant/${status}`);
    console.log(status);
  };

  const handleClientSelect = (status) => {
    navigate(`/progressuser/${status}`);
    console.log(status);
  };
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* 컨설턴트 그리드 */}
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  컨설턴트
                </Typography>
                {consultantData ? (
            consultantData
              .filter((user) => user.user_type === 'consultant')
              .map((consultantUser) => (
                <Paper
                  sx={{ p: 2, mb: 2, cursor: 'pointer' }}
                  key={consultantUser.id}
                  onClick={() => handleConsultantSelect(clientData[0].status)}
                >
                        <Typography variant="h6" gutterBottom>
                          {consultantUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {consultantUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {consultantUser.phone_number}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              {/* 고객 그리드 */}
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  고객
                </Typography>
                {clientData ? (
            clientData
              .filter((user) => user.user_type === 'client')
              .map((clientUser) => (
                <Paper
                  sx={{ p: 2, mb: 2, cursor: 'pointer' }}
                  key={clientUser.id}
                  onClick={() => handleClientSelect(clientData[0].status)}
                >
                        <Typography variant="h6" gutterBottom>
                          {clientUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {clientUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {clientUser.phone_number}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          화상 프로그램: {clientUser.program}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              <Grid item xs={12}>
        </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
