import React, { useState,useEffect } from 'react';
import { Box, Button, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import ValueGtmCanvas from './ValueGtmCanvas';
import Toolbar from '@mui/material/Toolbar';
import PlatformGtmCanvas1 from './PlatformGtmCanvas1';
import PlatformGtmCanvas2 from './PlatformGtmCanvas2';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const StrategyGtm = () => {
  const [uid,setUid] = React.useState(localStorage.getItem('uid'));
  const [open, setOpen] = useState(true);
  const [questions, setQuestions] = React.useState([]);
  const [savedAnswers, setSavedAnswers] = React.useState([]);
  const [insights, setInsights] = React.useState([]);
  const [questionType, setQuestionType] = React.useState('');
  const [businessType, setBusinessType] = React.useState();
  const [loading, setLoading] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    async function fetchBusinessType() {
      try {
        let BStype = localStorage.getItem('businessType');
        console.log(BStype);
        console.log(uid);
        BStype = await getBusinessType(uid);
        if (localStorage.getItem('userType') === 'consultant') {
          const clientId = localStorage.getItem('clientId');
          const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
          BStype = await getBusinessType(clientUidResponse.data);
          setUid(clientUidResponse.data);
        }
        setBusinessType(BStype);
        setQuestionType(BStype.includes('밸류체인') ? "GTM_밸류체인" : "GTM_플랫폼1");
      } catch (error) {
        console.error('Error fetching business type:', error);
      }
    }
    fetchBusinessType();
  },[]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // if (localStorage.getItem('userType') === 'consultant')
        // {
        //   const clientId = localStorage.getItem('clientId');
        //   const clientUidResponse = await axios.get(`http://localhost:8080/auth/getUidByUserId/userId=${clientId}`, {
        //       headers: {
        //           'Content-Type': 'application/json',
        //       },
        //   });

        //   setUid(clientUidResponse.data);
        //   const businessTypeData = await getBusinessType(clientUidResponse.data);
        //   setBusinessType(businessTypeData);
        // }
        // else
        //   setBusinessType(localStorage.getItem('businessType'));


        console.log('businessType:', businessType);
        console.log('questionType:', questionType);

        setLoading(true); 
        const [response, answerResponse, insightResponse] = await Promise.all([
          fetch(`${API_URL}/api/strategy/questionType=${questionType}`),
          fetch(`${API_URL}/api/answers/user/uid=${uid}`),
          fetch(`${API_URL}/api/insight`)
        ]);
        const data = await response.json();
        const savedAnswers = await answerResponse.json();
        const insights = await insightResponse.json();
        console.log("questions",data);
        console.log("savedAnswers",savedAnswers);
        console.log("insights",insights);

        const questionWithQuestionClass = data.map(question => {
          return {
            ...question,  
            questionClassList: question.questionClass.split("_").reverse(),
          };
        });

        
        const questionsWithsavedAnswers = questionWithQuestionClass.map(question => {
          const matchingAnswer = savedAnswers.find(answer => answer.questionId === question.questionId);
        
          if (matchingAnswer) {
            return {
              ...question,
              answerText: matchingAnswer.answerText,
            };
          }
        
          return question;
        });
        console.log("지금questions",questionsWithsavedAnswers)
        setQuestions(questionsWithsavedAnswers);
        setSavedAnswers(savedAnswers);
        setInsights(insights);
        setLoading(false);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [questionType]); // useEffect가 마운트 될 때 한 번만 호출
  
  const getBusinessType = async (clientUid) => {

    try {
      const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
        params: {
          uid: clientUid,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      return (response.data);
    } catch (error) {
      // Handle errors
      console.error('Error getting businessType:', error);
    }
  };


  const selectCanvas = (questionType) => {
    console.log("sa",savedAnswers)
    if (questionType === "GTM_밸류체인") {
      return <ValueGtmCanvas questions={questions} savedAnswers={savedAnswers} insights={insights} />;
    } else if (questionType === "GTM_플랫폼1") {
      return <PlatformGtmCanvas1 questions={questions} savedAnswers={savedAnswers} insights={insights} />;
    } else if (questionType === "GTM_플랫폼2") {
      return <PlatformGtmCanvas2 questions={questions} savedAnswers={savedAnswers} insights={insights} />;
    }
  };

  const buttonForPlatfromGtm = (questionType) => {
    const handleClick = (type) => {
      return () => { // 이벤트 핸들러를 반환하는 함수를 만듦
        setQuestions([]);
        setQuestionType(type);
      }
    };
    if (questionType === "GTM_플랫폼1") {
      console.log("now",questionType)
      return (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick("GTM_플랫폼2")}
            >
              GTM 플랫폼 2 전환
            </Button>
          </Grid>
        </Grid>
      );
    }
    else if (questionType === "GTM_플랫폼2") {
      console.log("now",questionType)

      return (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick("GTM_플랫폼1")}
            >
              GTM 플랫폼 1 전환
            </Button>
          </Grid>
        </Grid>
      );
    }
    else {
      return null;
    }
  }

  
const defaultTheme = createTheme();

  if(loading || questions.length === 0){
    console.log("nothing!",questions)
    return (
      <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
      </Box>
    </ThemeProvider>
    );
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {buttonForPlatfromGtm(questionType)}
                {selectCanvas(questionType)}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default StrategyGtm;

