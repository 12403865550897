// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    height: 100%;
    margin: 0;
    padding: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }

.tableHeader {
    background-color: #BDD7EE;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
    text-align: center;
  }

  
  .tableCell {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .tableRow {
    background-color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Document2Table.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,SAAS;IACT,UAAU;EACZ;EACA;IACE,yBAAyB;IACzB,WAAW;IACX,cAAc;EAChB;;AAEF;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;EACpB;;;EAGA;IACE,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".table-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    /* height: 100vh; */\n    height: 100%;\n    margin: 0;\n    padding: 0;\n  }\n  table {\n    border-collapse: collapse;\n    width: 100%;\n    margin: 0 auto;\n  }\n\n.tableHeader {\n    background-color: #BDD7EE;\n    color: #000;\n    font-size: 16px;\n    font-weight: bold;\n    padding: 8px;\n    text-align: center;\n  }\n\n  \n  .tableCell {\n    padding: 10px;\n    text-align: center;\n    border: 1px solid #ccc;\n  }\n  \n  .tableRow {\n    background-color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
