import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ORIGIN;
console.log(API_URL)

// Function to verify the accessToken
export const verifyToken = async (accessToken, uid) => {
  try {
    const response = await axios.post(`${API_URL}/auth/verifyToken`, {
      accessToken,
      uid
    });
    console.log("verifyToken 응답",response.data);
    if (response.data.isValid) {
        // accessToken valid test
        console.log('Access Token Valid !')
      return true;
    } else if (response.data.isExpired) {
      // If token is expired, try to refresh it
      const response1 = await refreshAccessToken();
      console.log("여기",response1);
      return response1;
      // return await refreshAccessToken();
    }
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};

export const verifyLogin = async (idToken, uid) => {
  
    try {
      const response = await axios.post(`${API_URL}/auth/verifyLogin`, {
        idToken,
        uid
      });
      
      if (response.data.isLoginValid) {
          // accessToken valid test
          console.log('Login (idToken) Valid !')
        return true;
      } 
    } catch (error) {
      console.error('Error verifying login (Error verifying idToken):', error);
      return false;
    }
  };

// Function to refresh the accessToken using the refreshToken
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const uid = localStorage.getItem('uid');

  try {
    const response = await axios.post(`${API_URL}/verifyToken`, {
      refreshToken,
      uid
    });

    console.log(response.data.newAccessToken)
    if (response.data.newAccessToken) {
      localStorage.setItem('accessToken', response.data.newAccessToken);
      return true;
    } else {
      // If refreshToken is also invalid, remove tokens and return false
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('uid');
      console.log("here")

      return false;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('uid');
    return false;
  }
};
