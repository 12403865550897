import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function Consultantlist() {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        console.log(data);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // 빈 배열을 두번째 매개변수로 전달하여 컴포넌트가 마운트될 때만 실행

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {userData ? (
                  userData
                    .filter((user) => user.uid === localStorage.getItem('uid'))
                    .map((consultantUser) => (
                      <Grid item xs={12} sm={6} md={4} key={consultantUser.id}>
                        <Paper sx={{ p: 1 }}>
                          <Typography variant="h6" gutterBottom>
                            {consultantUser.username}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Email: {consultantUser.email}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Phone Number: {consultantUser.phone_number}
                          </Typography>
                          <Button onClick={() => navigate('/forgot')}>비밀번호 변경하기</Button>
                        </Paper>
                      </Grid>
                    ))
                ) : (
                  // 데이터가 로드되지 않았을 때의 로딩 상태 표시
                  <div>Loading...</div>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}