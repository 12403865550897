import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_ORIGIN;
const drawerWidth = 240;

export default function AdminMatching() {
  const [open, setOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [matchingDialogOpen, setMatchingDialogOpen] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [matchingText, setMatchingText] = useState("");
  const [matchingCompleted, setMatchingCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        console.log(data);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = createTheme();

  const handleConsultantSelect = (consultantId) => {
    setSelectedConsultant(consultantId);
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
  };

  const handleOpenMatchingDialog = () => {
    setMatchingDialogOpen(true);
  };

  const handleCloseMatchingDialog = () => {
    setMatchingDialogOpen(false);
  };

  const handleMatching = async () => {
    const today = new Date().toISOString().slice(0, 10);
    if (selectedConsultant && selectedClient && matchingText) {
      console.log(selectedClient);
      console.log(selectedConsultant);
      console.log(matchingText);
      try {
        await Axios.post(`${API_URL}/admin/createConsultEngagement/consultantId=${selectedConsultant}/clientId=${selectedClient}/businessName=${matchingText}/startDate=${today}`);
        
        const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${selectedClient}`);
        const clientUid = await clientUidResponse.text();
        
        await Axios.post(`${API_URL}/auth/setStatus`, {
          uid: clientUid,
          status: 1,
        });

        console.log("Client Status updated successfully");
        setMatchingCompleted(true);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        handleCloseMatchingDialog(); // 매칭 완료 후 다이얼로그 닫기
      }
    }
  };

  const handleCloseDialog = () => {
    setMatchingCompleted(false);
    window.location.reload();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  컨설턴트
                </Typography>
                {userData ? (
                  userData
                    .filter((user) => user.user_type === 'consultant')
                    .map((consultantUser) => (
                      <Paper
                        sx={{ p: 2, mb: 2, cursor: 'pointer', backgroundColor: selectedConsultant === consultantUser.id ? '#e0e0e0' : 'inherit' }}
                        key={consultantUser.id}
                        onClick={() => handleConsultantSelect(consultantUser.id)}
                      >
                        <Typography variant="h6" gutterBottom>
                          {consultantUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {consultantUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {consultantUser.phone_number}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  고객
                </Typography>
                {userData ? (
                  userData
                    .filter((user) => user.user_type === 'client')
                    .map((clientUser) => (
                      <Paper
                        sx={{ p: 2, mb: 2, cursor: 'pointer', backgroundColor: selectedClient === clientUser.id ? '#e0e0e0' : 'inherit' }}
                        key={clientUser.id}
                        onClick={() => handleClientSelect(clientUser.id)}
                      >
                        <Typography variant="h6" gutterBottom>
                          {clientUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {clientUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {clientUser.phone_number}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          회의 프로그램: {clientUser.program}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="contained" color="primary" onClick={handleOpenMatchingDialog} disabled={!selectedConsultant || !selectedClient}>
                    사업 매칭하기
                  </Button>
                  <Button variant="outlined" onClick={() => navigate('/MainAdmin')}>
                    돌아가기
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Dialog open={matchingDialogOpen} onClose={handleCloseMatchingDialog}>
            <DialogTitle>사업 명 입력</DialogTitle>
            <DialogContent>
              <DialogContentText>
                매칭할 사업 명을 입력해주세요.
              </DialogContentText>
              <input type="text" value={matchingText} onChange={(e) => setMatchingText(e.target.value)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMatching} color="primary">
                매칭하기
              </Button>
              <Button onClick={handleCloseMatchingDialog} color="secondary">
                취소
              </Button>
            </DialogActions>
          </Dialog>
          {matchingCompleted && (
            <Dialog open={matchingCompleted} onClose={handleCloseDialog}>
              <DialogTitle>매칭 완료</DialogTitle>
              <DialogContent>
                <DialogContentText>매칭이 성공적으로 완료되었습니다.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                  확인
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
