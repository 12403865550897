import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { useEffect, useState, useMemo } from 'react';
const API_URL = process.env.REACT_APP_API_ORIGIN;


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'question',
      headerName: '질문',
      width: 300,
      sortable: false,
      editable: true,
    },
    {
      field: 'answerText',
      headerName: '답변',
      width: 300,
      sortable: false,
      editable: true,
      
    },
    {
      field: 'AS_IS',
      headerName: 'AS_IS',
      width: 300,
      sortable: false,
      editable: true,
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      editable: true,
    },
  ];
  


// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;

//   const handleClick = () => {
//     const id = randomId();
//     setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
//     setRowModesModel((oldModel) => ({
//       ...oldModel,
//       [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
//     }));
//   };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         Add record
//       </Button>
//     </GridToolbarContainer>
//   );
// }

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientId = localStorage.getItem('clientId');//현재 보고자하는 uid로 변경해줄것!!!

        const [response, answerResponse] = await Promise.all([
          fetch(`${API_URL}/api/questions`),
          fetch(`${API_URL}/api/answers/user/userId=${clientId}`),
        ]);
        const data = await response.json();
        const savedAnswer = await answerResponse.json();
        console.log("savedAnswer",savedAnswer);

        
        const questionsWithSavedAnswer = data.map((question, index) => {
          const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
          if (matchingAnswer) {
            return {
              ...question,
              id: index + 1,
              answerText: matchingAnswer.answerText,
            };
          }
          return {
            ...question,
            id: index + 1,
          };
        });
        setRows(questionsWithSavedAnswer);
        console.log("data",questionsWithSavedAnswer);


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const getUidById = async (clientId) => {
    try {
      const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
      const clientUid = await clientUidResponse.text();
      return clientUid;
    }
    catch (error) {
      console.error('Error getting clientId:', error);
    }
  }

  const sendUpdateToServer = async (updatedRow) => {
    
      try {
        const clientId = localStorage.getItem('clientId');
        const clientUid = await getUidById(clientId);
        const updateData = { ...updatedRow, uid: clientUid};

        // uid 속성 추가
        console.log("받은인자",updatedRow);
        console.log("updateData",updateData);

        if (!clientUid) {
          console.error('Error: clientUid not found');
          return;
        }
  
        await fetch(`${API_URL}/api/answers/update`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateData),
        });
        console.log('updated successfully')
      } catch (error) {
        console.error('Error updating data:', error);
      }
    };
  

  const processRowUpdate = async (newRow) => {
    console.log("newRow",newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    await sendUpdateToServer(newRow);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'question',
        headerName: '질문',
        width: 300,
        sortable: false,
        editable: false,
    },
    {
        field: 'answerText',
        headerName: '답변',
        width: 300,
        sortable: false,
        editable: true,
        
    },
    {
        field: 'AS_IS',
        headerName: 'AS_IS',
        width: 300,
        sortable: false,
        editable: true,
    },
    {
        field: 'keyword',
        headerName: 'Keyword',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        editable: true,
    },
    
      
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 800,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
            ...column,
            renderCell: (params) => (
              <div style={{ whiteSpace: 'pre-line' }}>
                {params.value}
              </div>
            ),
          }))}
        getRowHeight={() => 150} // 셀의 높이
        columnBuffer={5} // 컬럼 버퍼 설정
        editMode="cell"
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        // slots={{
        //   toolbar: EditToolbar,
        // }}
        // slotProps={{
        //   toolbar: { setRows, setRowModesModel },
        // }}
      />
    </Box>
  );
}
