import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function Consulting() {
    const toggleDrawer = () => {
        setOpen(!open);
      };
  const [open, setOpen] = React.useState(true);
  const [consultingData, setConsultingData] = React.useState([]);

  const navigate = useNavigate();

  // Consultant의 정보
  var userType = localStorage.getItem('userType');
  var uid = localStorage.getItem('uid');

  function getStatusText(status) {
    switch (status) {
      case 1:
        return "승인"; // Approved
      case -1:
        return "거부"; // Rejected
      case 0:
        return "승인 대기 중"; // Pending approval
      default:
        return "상태 미정"; // Undefined status or any other default message you want
    }
  }

  const handlePaperClick = (consulting) => {
    console.log('Paper clicked:', consulting);
    //navigate to ConstultingTable.js
    navigate('/ConsultantFile');
    //send array clientId to localStorage
    localStorage.setItem('clientId', consulting.clientId);
  };

  // Consultant와 Consulting으로 연결된 Customer를 백앤드로부터 가져옴
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getConsultEngagement/consultantUid=` + uid);
        let data = await response.json(); // Assuming 'data' is an array        
        if (Array.isArray(data) && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            console.log(data);
            const usernameResponse = await fetch(`${API_URL}/auth/getUsernameByUserId/userId=` + data[i].clientId);
            const username = await usernameResponse.text();
            data[i].username = username;
            // data = data.map(item => ({ ...item, username }));

            // console.log("username", username);
            
          }
        }
      
        console.log("data", data);
      
        setConsultingData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []); // 빈 배열을 두번째 매개변수로 전달하여 컴포넌트가 마운트될 때만 실행


const defaultTheme = createTheme();

return (
<ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={2}>
              {consultingData ? (
                consultingData.map((consulting) => (
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240,
                      }}
                      onClick={() => handlePaperClick(consulting)}
                    >
                      <Typography variant="h6" gutterBottom>
                        {consulting.businessName}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {consulting.username}
                      </Typography>
                      
                      {/* <Typography variant="h6" gutterBottom>
                        {consulting.consultId}
                      </Typography> */}
                      <Typography variant="h6" gutterBottom>
                        {getStatusText(consulting.status)}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {consulting.startDate}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {consulting.endDate ? consulting.endDate : "진행 중"}
                      </Typography>
                    </Paper>
                  </Grid>
                ))
              ) : (
                // 데이터가 로드되지 않았을 때의 로딩 상태 표시
                <div>Loading...</div>
              )}
            </Grid>
          </Container>
        </Box>
    </Box>
</ThemeProvider>
);
}

