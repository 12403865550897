import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useMemo } from 'react';
import Table2 from './CTable2';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;




export default function ConsultantDiagnosis() {
    const [rows, setRows] = useState([]);
    const [clientUid, setClientUid] = useState('');
    const [editIdx, setEditIdx] = useState(-1);
    const [navopen, setNavOpen] = useState(true);
    const [open, setOpen] = useState(false);//제출확인창
    const [openSnackbar, setOpenSnackbar] = useState(false);//수정완료창
    const [businessType, setBusinessType] = useState('플랫폼_블루오션')
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientId = localStorage.getItem('clientId');//현재 보고자하는 uid로 변경해줄것!!!
                const clientUid = await getUidById(clientId);
                setClientUid(clientUid)


                const [response, answerResponse] = await Promise.all([
                    fetch(`${API_URL}/api/questions`),
                    fetch(`${API_URL}/api/answers/user/uid=${clientUid}`),
                ]);
                const data = await response.json();
                const savedAnswer = await answerResponse.json();
                console.log("savedAnswer", savedAnswer);


                const questionsWithSavedAnswer = data.map((question, index) => {
                    const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
                    if (matchingAnswer) {
                        console.log(matchingAnswer)
                        return {
                            ...question,
                            id: index + 1,
                            answerText: matchingAnswer.answerText,
                            asIs: matchingAnswer.asIs,
                            keyword: matchingAnswer.keyword,
                        };
                    }
                    return {
                        ...question,
                        id: index + 1,
                    };
                });
                // 병합된 행을 처리하기 위한 로직
                const mergedRows = questionsWithSavedAnswer.reduce((acc, item, index, array) => {
                    // 첫 번째 행이거나 이전 행과 'questionClass' 값이 다른 경우
                    if (index === 0 || item.questionClass !== array[index - 1].questionClass) {
                        // 'rowspan' 값을 계산
                        let rowspan = 1;
                        for (let i = index + 1; i < array.length; i++) {
                            if (array[i].questionClass === item.questionClass) {
                                rowspan++;
                            } else {
                                break;
                            }
                        }
                        acc.push({ ...item, rowspan: rowspan });
                    } else {
                        // 이전 행과 'questionClass' 값이 같으면 생략 (병합된 행의 일부)
                        acc.push({ ...item, isMerged: true });
                    }
                    return acc;
                }, []);
                console.log("merged", mergedRows)
                setRows(mergedRows);
                console.log("data", questionsWithSavedAnswer);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const getUidById = async (clientId) => {
        try {
            const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const clientUid = await clientUidResponse.text();
            return clientUid;
        }
        catch (error) {
            console.error('Error getting clientId:', error);
        }
    }

    const sendUpdateToServer = async (updatedRow) => {

        try {
            const updateData = { ...updatedRow, uid: clientUid };

            // uid 속성 추가
            console.log("받은인자", updatedRow);
            console.log("updateData", updateData);


            await fetch(`${API_URL}/api/answers/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            console.log('updated successfully')
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleEdit = (idx) => {
        setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
    };

    const handleSave = async (idx) => {
        setEditIdx(-1); // 편집 모드를 종료합니다.
        await sendUpdateToServer(rows[idx]); // 변경된 행을 서버로 전송합니다.
        setOpenSnackbar(true);
    };

    const handleChange = (e, idx, column) => {
        const updatedData = rows.map((row, index) =>
            index === idx ? { ...row, [column]: e.target.value } : row
        );
        setRows(updatedData); // 키워드 변경사항을 상태에 반영합니다.
    };

    const ConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시

            await onConfirm(); // 실제 작업 수행
            navigate("/ChangeQuestion");
            setConfirming(false);
        };
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>수정된 답변을 저장하고 사업/시장유형을 저장하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">고객의 사업아이디어 진단 질문을 수정하는 페이지로 넘어갑니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const Diagnosis = async () => {
        try {
            const response = await axios.post(`${API_URL}/auth/setBusinessType`, {
                uid: clientUid,
                businessType: businessType,
            });
            console.log(response.data);
            setOpen(false);

        } catch (error) {
            console.error('Error submitting documents:', error);
        }
    };

    const toggleDrawer = () => {
        setNavOpen(!navopen);
    };

    const defaultTheme = createTheme();


    return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={navopen} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} />
                <Box sx={{ height: "100%", marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 20, backgroundColor: 'white' }}>
                    {/* <Table2/> */}
                    <h2>사업/시장 유형 설정하기</h2>
                    <table className="MyTable">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>질문</th>
                                <th>답변</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.slice(0, 19).map((item, idx) => {
                                // 병합되어야 할 셀인지 확인
                                const isMergedCell = item.rowspan >= 1;
                                return (
                                    <tr key={idx}>
                                        {isMergedCell && (
                                            <td rowSpan={item.rowspan || 1}>{item.questionClass}</td>
                                        )}
                                        <td>{item.question}</td>
                                        <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }} >
                                            {editIdx === idx ? (

                                                <Textarea
                                                    value={item.answerText}
                                                    onChange={(e) => handleChange(e, idx, "answerText")}
                                                    onBlur={() => handleSave(idx)}
                                                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                                                />

                                            ) : (
                                                <span>{item.answerText}</span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
                        <FormControl sx={{ minWidth: 180, marginLeft: 'auto' }}>
                            <InputLabel id="business-type-label">사업/시장 유형</InputLabel>
                            <Select
                                labelId="business-type-label"
                                id="business-type"
                                value={businessType}
                                onChange={(e) => setBusinessType(e.target.value)}
                            >
                                <MenuItem value="">선택</MenuItem>
                                <MenuItem value="플랫폼_블루오션">플랫폼_블루오션</MenuItem>
                                <MenuItem value="플랫폼_신제품">플랫폼_신제품</MenuItem>
                                <MenuItem value="플랫폼_신규고객">플랫폼_신규고객</MenuItem>
                                <MenuItem value="플랫폼_기존시장">플랫폼_기존시장</MenuItem>
                                <MenuItem value="밸류체인_블루오션">밸류체인_블루오션</MenuItem>
                                <MenuItem value="밸류체인_신제품">밸류체인_신제품</MenuItem>
                                <MenuItem value="밸류체인_신규고객">밸류체인_신규고객</MenuItem>
                                <MenuItem value="밸류체인_기존시장">밸류체인_기존시장</MenuItem>
                            </Select>
                        </FormControl>
                        <Fab color="secondary"
                            varient="extended"
                            onClick={() => setOpen(true)}
                            sx={{
                                width: 100,
                                marginLeft: 3,
                                borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                                boxShadow: '0, 0, 0, 0.5',
                            }} >
                            <span>제출</span>
                            <RightArrow />
                        </Fab>
                    </Box>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                        <Alert
                            onClose={() => setOpenSnackbar(false)}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            수정사항이 저장되었습니다!
                        </Alert>
                    </Snackbar>
                    <ConfirmDialog onClose={() => setOpen(false)} onConfirm={Diagnosis} />

                </Box>
                </Box>
    );
}

