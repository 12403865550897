import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ActionDetail = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };



    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 상단에 FACT 단계 제목 */}
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left">
                                    Action 단계
                                </Typography>
                                <Divider />
                            </Grid>

                            {/* 가로로 반으로 나뉘어진 섹션 */}
                            <Grid item xs={6}>
                                {/* 첫 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    창업자가 신사업 아이디어를 현실에 구현하기 위한 실행 과제를 도출
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                {/* 두 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>GOAL</strong><br />
                                    ● GTM 실행과제 도출<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>STEPS</strong><br />
                                    ● 사업 유형별 GTM 캔버스 개발 리뷰<br />
                                    ● GTM 실행과제 도출<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>OUTCOMES</strong><br />
                                    ● GTM 실행과제 계획<br />
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 버튼 추가 */}
                        <Grid item xs={12} style={{ marginTop: '24px', textAlign: 'center' }}>
                            <button onClick={() => navigate('/MainStrategy')} style={{ fontSize: '12px', padding: '8px 36px' }}>
                                다 음
                            </button>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ActionDetail;
