import React, { useState,useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Chat from './Chat_insight';
import Documents from './Document';
import Toolbar from '@mui/material/Toolbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;


const MainStrategy = () => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(true);

  const [questiontype, setQuestionType] = useState('GTM_벨류체인');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);

  const [questionTypeIndices, setQuestionTypeIndices] = useState({});
  const [isQASessionOver, setIsQASessionOver] = React.useState(false);

  const [questions, setQuestions] = React.useState([]);




  const handleTabChange = (newTab) => {
    setQuestionType(newTab);
    setCurrentQuestionIndex(questionTypeIndices[newTab]);
    console.log("index",questionTypeIndices);
  };


  const toggleDrawer = () => {
    setOpen(!open);
  };

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = localStorage.getItem('uid');
        const [response, answerResponse] = await Promise.all([
          fetch(`${API_URL}/api/strategy`),
          fetch(`${API_URL}/api/answers/user/uid=${uid}`),
        ]);
        const data = await response.json();
        const savedAnswer = await answerResponse.json();
        console.log("questions",data);
        console.log("savedAnswer",savedAnswer);

        
        const questionsWithSavedAnswer = data.map(question => {
          const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
        
          if (matchingAnswer) {
            return {
              ...question,
              answerText: matchingAnswer.answerText,
            };
          }
        
          return question;
        });
        console.log("hi",questionsWithSavedAnswer);
        setQuestions(questionsWithSavedAnswer);
        setFilteredQuestions(questionsWithSavedAnswer);

        
        // question type별 시작index 0으로 초기화
        let updatedQuestionTypeIndices = {};
        data.forEach((question, index) => {
        if (!(question.questionType in updatedQuestionTypeIndices)) {
        updatedQuestionTypeIndices[question.questionType] = 0;
          }
        });
        console.log("questiontype",updatedQuestionTypeIndices);
        if (JSON.stringify(updatedQuestionTypeIndices) !== JSON.stringify(questionTypeIndices)) {
          setQuestionTypeIndices(updatedQuestionTypeIndices);
        }


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }, []); // useEffect가 마운트 될 때 한 번만 호출


  useEffect(() => {
    const filtered = questions.filter(question => question.questionType === questiontype);
    if (JSON.stringify(filtered) !== JSON.stringify(filteredQuestions)) {
      setFilteredQuestions(filtered);
      console.log("filter", filtered);
    }
  }, [questiontype, questions, filteredQuestions]);
  
  



  if(questions.length === 0){
    return null;
  }


  
  const handleAnswerSubmit = (answeredQuestion) => {
    if (!isQASessionOver) {

      const updatedQuestions = questions.map((q, question_index) => {
        if (q.questionId === answeredQuestion.questionId) {
          console.log(question_index);
          console.log(q);
          return { ...q, uid: uid, answerText: answeredQuestion.answer };
        }
        return q;
      });
      setQuestions(updatedQuestions);
    }
  };



  const setNextQuestion = () => {
    setCurrentQuestionIndex(prevIndex => {
      // Check if the current question is the last one
      if (prevIndex >= filteredQuestions.length - 1) {
        // Inform Chat that QA session is over
        // This can be done by setting a special state or sending a special message
        setIsQASessionOver(true); 
        return prevIndex;
      }
  
      const nextIndex = prevIndex + 1;
      // Update questionTypeIndices as well
      setQuestionTypeIndices(prevIndices => ({
        ...prevIndices,
        [questiontype]: nextIndex,  // Change here to use questiontype
      }));
      
      console.log("nextIndex",nextIndex);
      return nextIndex;
    });
  };

  const defaultTheme = createTheme();


  if (questions.length === 0) {
    // 데이터가 아직 로드되지 않은 경우 
    return null;
  }
  
  const currentQuestion = filteredQuestions[currentQuestionIndex];
  if (!currentQuestion) {
    return null;
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Tabs value={questiontype} onChange={(e, newTab) => handleTabChange(newTab)} sx={{ display: 'flex', flexGrow: 1 }}>
              <Tab label="GTM_벨류체인" value="GTM_벨류체인" sx={{ flexGrow: 1 }}/>
              <Tab label="GTM_플랫폼" value="GTM_플랫폼" sx={{ flexGrow: 1 }}/>
            </Tabs>
          <Grid container spacing={3}>
          <Grid item xs={6}>
              <Chat 
                currentQuestion={currentQuestion} 
                onAnswerSubmit={handleAnswerSubmit} 
                setNextQuestion={setNextQuestion}
                isQASessionOver={isQASessionOver}
                questionType={questiontype}
              />
            </Grid>
            <Grid item xs={6}>
              <Documents questions={filteredQuestions} />
            </Grid>
          </Grid>
          </Container>
        </Box>
      </Box>
      
    </ThemeProvider>
  );
};

export default MainStrategy;

