import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function UserList() {
    const { groupId } = useParams();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userresponse = await fetch(`${API_URL}/consultant/getUserList`);
                const userdata = await userresponse.json();
                if (groupId == 0) {
                    setUsers(userdata);
                }
                else {
                    const filteredUsers = userdata.filter(user => user.groupId === parseInt(groupId));
                    setUsers(filteredUsers);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [groupId]);

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Users in Group {groupId}
            </Typography>
            <List>
                {users.map(user => (
                    <ListItem key={user.id}>
                        <ListItemText primary={user.name} secondary={user.email} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
