// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  thead th {
    background-color: #f4b183;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #000;
  }
  
  .checkbox-cell {
    width: 50px;
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  
  tbody td {
    border: 1px solid #000;
    padding: 10px;
    font-size: 12px;
    min-width: 120px; /* 셀의 최소 너비 설정 */
  }
  
  /* TextField 스타일 조정 */
  .MuiInputBase-root {
    width: 100%; /* 셀 내부에서 TextField가 전체 너비를 차지하도록 설정 */
  }`, "",{"version":3,"sources":["webpack://./src/styles/factTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,eAAe;IACf,gBAAgB,EAAE,gBAAgB;EACpC;;EAEA,qBAAqB;EACrB;IACE,WAAW,EAAE,sCAAsC;EACrD","sourcesContent":[".table-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  table {\n    border-collapse: collapse;\n    width: 100%;\n    margin: 0 auto;\n    text-align: center;\n  }\n  \n  thead th {\n    background-color: #f4b183;\n    padding: 10px;\n    font-weight: bold;\n    border: 1px solid #000;\n  }\n  \n  .checkbox-cell {\n    width: 50px;\n  }\n  \n  input[type=\"checkbox\"] {\n    width: 20px;\n    height: 20px;\n  }\n  \n  tbody td {\n    border: 1px solid #000;\n    padding: 10px;\n    font-size: 12px;\n    min-width: 120px; /* 셀의 최소 너비 설정 */\n  }\n  \n  /* TextField 스타일 조정 */\n  .MuiInputBase-root {\n    width: 100%; /* 셀 내부에서 TextField가 전체 너비를 차지하도록 설정 */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
