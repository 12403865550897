// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }
  
  td {
    border: 1px solid #000;
    padding: 10px;
    vertical-align: middle; /* 수직 가운데 정렬 */
    text-align: left; /* 기본 왼쪽 정렬 */
  }
  
  .category-cell {
    width: 20%;
    text-align: center; /* 제목은 가운데 정렬 */
  }
  
  .details-cell {
    width: 30%;
  }
  
  .description-cell {
    width: 50%;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/CategoryTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,sBAAsB,EAAE,cAAc;IACtC,gBAAgB,EAAE,aAAa;EACjC;;EAEA;IACE,UAAU;IACV,kBAAkB,EAAE,eAAe;EACrC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".table-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    /* height: 100vh; */\n    height: 100%;\n    margin: 0;\n    padding: 0;\n  }\n  \n  table {\n    border-collapse: collapse;\n    width: 100%;\n    margin: 0 auto;\n  }\n  \n  td {\n    border: 1px solid #000;\n    padding: 10px;\n    vertical-align: middle; /* 수직 가운데 정렬 */\n    text-align: left; /* 기본 왼쪽 정렬 */\n  }\n  \n  .category-cell {\n    width: 20%;\n    text-align: center; /* 제목은 가운데 정렬 */\n  }\n  \n  .details-cell {\n    width: 30%;\n  }\n  \n  .description-cell {\n    width: 50%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
