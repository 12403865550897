import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import InsightsIcon from '@mui/icons-material/Insights';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useTheme } from '@mui/material/styles';
import Axios from 'axios';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const ProgressUser = () => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(true);
  const {status} = useParams(); // 초기 상태값은 0로 설정
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const user = {
    uid: uid
  };

  const routes = [
    { path: `/FactFile/${uid}`, label: <p>Fact 보고서 <br /> 확인하기</p>, status: 11 },
    { path: `/InsightFile/${uid}`, label: <p>Insight 보고서 <br /> 확인하기</p>, status: 21 },
    { path: '/StrategyDetail', label: <p>Strategy 보고서 <br /> 확인하기</p>, status: 31 },
    { path: '/ActionDetail', label: <p>Action 보고서 <br /> 확인하기</p>, status: 41 }
  ];

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column', // 세로 방향으로 정렬
            alignItems: 'center', // 가로 중앙 정렬
            height: '100%', // '100vh'에서 '100%'로 수정
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} style={{ height: '100%' }}>
              {/* 그리드 1: Fact */}
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <TroubleshootIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Fact</h3>

                  {/* 사업 / 시장 유형 진단 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: status >= 1 && status < 3 ? theme.palette.primary.main : 
                      status >= 3 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: status >= 1 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                    }}
                  >
                    사업 / 시장 유형 진단
                  </p>

                  {/* 사업아이디어 진단 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: status >= 3 && status < 5 ? theme.palette.primary.main : 
                      status >= 5 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: status >= 3 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                    }}
                  >
                    사업아이디어 진단
                  </p>
                </div>
              </Grid>

              {/* 그리드 2: Insight */}
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <InsightsIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Insight</h3>

                  {/* SOM 후보 도출 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: status >= 11 && status < 15 ? theme.palette.primary.main : 
                      status >= 15 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: status >= 11 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                    }}
                  >
                    SOM 후보 도출
                  </p>
                </div>
              </Grid>

              {/* 그리드 3: Strategy */}
              <Grid item xs={3}>
                {/* 여기에 Strategy 컴포넌트 또는 내용을 추가 */}
                <div style={{ textAlign: 'center' }}>
                  <EmojiObjectsIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Strategy</h3>
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: status >= 21 && status < 22 ? theme.palette.primary.main : 
                      status >= 22 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: status >= 21 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                    }}
                  >
                    SOM 후보 평가 & <br/>최종 SOM 구체화
                  </p>
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: status >= 22 && status < 30 ? theme.palette.primary.main : 
                      status >= 30 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: status >= 22 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                    }}
                  >
                    GTM 전략
                  </p>
                </div>
              </Grid>

              {/* 그리드 4: Action */}
              <Grid item xs={3}>
                {/* 여기에 Action 컴포넌트 또는 내용을 추가 */}
                <div style={{ textAlign: 'center' }}>
                  <PlayCircleFilledWhiteIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Action</h3>
                  <p style={{
                    padding: '10px',
                    backgroundColor: status >= 30 ? theme.palette.primary.main : theme.palette.grey[300],
                    borderRadius: '10px',
                    boxShadow: theme.shadows[2],
                    color: status >= 30 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    transition: 'background-color 0.3s, color 0.3s',
                    cursor: 'default' // 클릭 시 포인터 모양 기본으로 유지
                  }}>GTM 실행 과제 수립</p>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} style={{ height: '100%' }}>
              {routes.map((route, index) => (
                <Grid item xs={3} key={index}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={status < route.status}
                    style={{
                      height: '100%',
                      cursor: 'default', // 클릭 시 포인터 모양 기본으로 유지
                      boxShadow: status >= route.status ? theme.shadows[5] : 'none',
                      backgroundColor: status >= route.status ? theme.palette.primary.main : theme.palette.grey[300],
                      color: status >= route.status ? theme.palette.primary.contrastText : theme.palette.text.primary
                    }}
                  >
                    {route.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ProgressUser;
