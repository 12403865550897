// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
    display: flex;
  }
  
  .step {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: #cccccc90; /* 기본 배경색 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  }
  
  .step.active {
    background-color: #007bff; /* 활성화된 단계의 배경색 */
    color: #fff; /* 활성화된 단계의 글자색 */
  }
  
  .step.completed {
    /* background-color: #94c1eea3; 완료된 단계의 배경색 */
    background-color: #e7eff8a3; /* 완료된 단계의 배경색 */
    color: #000; /* 완료된 단계의 글자색 */
  }`, "",{"version":3,"sources":["webpack://./src/styles/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,4BAA4B;IAC5B,kBAAkB;IAClB,2BAA2B,EAAE,WAAW;IACxC,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB,EAAE,iBAAiB;IAC5C,WAAW,EAAE,iBAAiB;EAChC;;EAEA;IACE,6CAA6C;IAC7C,2BAA2B,EAAE,gBAAgB;IAC7C,WAAW,EAAE,gBAAgB;EAC/B","sourcesContent":[".progress-bar {\n    display: flex;\n  }\n  \n  .step {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px 10px;\n    cursor: pointer;\n    /* border: 1px solid #ccc; */\n    border-radius: 5px;\n    background-color: #cccccc90; /* 기본 배경색 */\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); \n  }\n  \n  .step.active {\n    background-color: #007bff; /* 활성화된 단계의 배경색 */\n    color: #fff; /* 활성화된 단계의 글자색 */\n  }\n  \n  .step.completed {\n    /* background-color: #94c1eea3; 완료된 단계의 배경색 */\n    background-color: #e7eff8a3; /* 완료된 단계의 배경색 */\n    color: #000; /* 완료된 단계의 글자색 */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
