import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { verifyToken } from './AuthService'; // authService to verify the token
import { useEffect, useState } from 'react';

const AuthToken = async ({ children }) => {
  const location = useLocation();
  const accessToken = localStorage.getItem('accessToken');
  const userID = localStorage.getItem('userID');

  const isValidToken = await verifyToken(accessToken, userID); // Function to verify token from backend

  console.log("확인",isValidToken);
  if (!isValidToken) {
    console.log("Something went wrong with accessToken. Please relogin")
    return <Navigate to='/SignIn' state={{ from: location }} replace />;
  }
  console.log("Access Token vertification Successful")
  return children;
};
export default AuthToken;