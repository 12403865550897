import React from 'react';
import '../../styles/CategoryTable.css'

const text1_1 = "전통적인 구매 - 제조 - 물류 마케팅 - 영업 등과 같은 수직적 밸류체인을 혁신하여 새롭게 경쟁력을 창조하는 사업 모델(일반 제조/유통업 등)";
const text2_1 = "수요자측 참여자와 공급측 참여자의 자발적인 상호작용 속에서 제품/서비스/정보/기술 등의 제공가치가 교환되게 하는 사업모델";

const CategoryTable = () => {
  return (
    <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
            <th colSpan="5" className="header">사업모델 유형 설명</th>
        </tr>
    </thead>
      <tbody>
        {/* First row */}
        <tr>
          <td style={{ border: '1px solid black', width: '20%', verticalAlign: 'middle', textAlign: 'center', padding: '10px' }}>
            <h2>1. 밸류체인형</h2>
          </td>
          <td style={{ border: '1px solid black', width: '30%', verticalAlign: 'middle', textAlign: 'left', padding: '10px' }}>
            {text1_1}
          </td>
          <td style={{ border: '1px solid black', width: '50%', verticalAlign: 'middle', textAlign: 'left', padding: '10px' }}>
            <h3>특징:</h3>
            <ul>
              <li>제조회사의 경우, 매출액 증가에 비례해 이익률 상승</li>
              <li>제품판매 후 이익이 마케팅과 판촉에 재투자되면서 회사는 신규 고객을 확보해 나감</li>
              <li>성장률은 크게 세 가지에 의지하는데, 첫 번째는 각 고객이 발생시키는 이익, 두 번째는 새 고객 확보에 드는 비용, 세 번째는 기존 고객의 재구매율</li>
              <li>이러한 수치가 좋으면 좋을수록 회사는 더 빨리 성장하고 이익은 더 커짐</li>
            </ul>
          </td>
        </tr>
        {/* Second row */}
        <tr>
          <td style={{ border: '1px solid black', width: '20%', verticalAlign: 'middle', textAlign: 'center', padding: '10px' }}>
            <h2>2. 플랫폼형</h2>
          </td>
          <td style={{ border: '1px solid black', width: '30%', verticalAlign: 'middle', textAlign: 'left', padding: '10px' }}>
            {text2_1}
          </td>
          <td style={{ border: '1px solid black', width: '50%', verticalAlign: 'middle', textAlign: 'left', padding: '10px' }}>
            <h3>특징:</h3>
            <ul>
              <li>플랫폼형의 성공은 네트워크 효과를 통해 판매자와 구매자가 만나 거래가 성사되는 장소로 확고히 자리를 잡아야 가능</li>
              <li>판매자는 잠재적 고객이 최대한 많은 장소에서 영업하기를 원하고, 구매자들은 판매자들이 열심히 경쟁하여 좋은 제품을 저렴한 가격에 내놓는 장소에서 구매하기를 원함</li>
              <li>이러한 종류의 서비스를 제공하는 스타트업에서는 새롭게 유입된 구매자와 판매자의 유지를 측정하여 네트워크 효과가 작동하는지를 보는 것이 중요</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default CategoryTable;
