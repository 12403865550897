// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyTable {
    width: 100%;
    border-collapse: collapse; /* 이 속성은 테두리를 겹치게 합니다 */
  }
  
.MyTable th, .MyTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

.MyTable th {
    background-color: #BDD7EE;
  }
.MyTable .column-style {
    background-color: #F8CBAD;
  }

.MyTable th:nth-child(1),
.MyTable td:nth-child(1) {
    /* width: 10%; 첫 번째 컬럼 너비 */
    width: 150px; /* 첫 번째 컬럼 너비 */
    }

.MyTable th:nth-child(2),
.MyTable td:nth-child(2) {
    width: 450px; /* 두 번째 컬럼 너비 */
    }

.MyTable th:nth-child(3),
.MyTable td:nth-child(3) {
    /* width: 20%; 세 번째 컬럼 너비 */
    width: 300px; /* 세 번째 컬럼 너비 */
    }

.MyTable th:nth-child(4),
.MyTable td:nth-child(4) {
    /* width: 20%; 네 번째 컬럼 너비 */
    width: 300px; /* 네 번째 컬럼 너비 */
    }

.MyTable th:nth-child(5),
.MyTable td:nth-child(5) {
    /* width: 10%; 다섯 번째 컬럼 너비 */
    /* width: 100px; */
    }
`, "",{"version":3,"sources":["webpack://./src/styles/ConsultingTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB,EAAE,uBAAuB;EACpD;;AAEF;IACI,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;EAClB;;AAEF;IACI,yBAAyB;EAC3B;AACF;IACI,yBAAyB;EAC3B;;AAEF;;IAEI,2BAA2B;IAC3B,YAAY,EAAE,eAAe;IAC7B;;AAEJ;;IAEI,YAAY,EAAE,eAAe;IAC7B;;AAEJ;;IAEI,2BAA2B;IAC3B,YAAY,EAAE,eAAe;IAC7B;;AAEJ;;IAEI,2BAA2B;IAC3B,YAAY,EAAE,eAAe;IAC7B;;AAEJ;;IAEI,4BAA4B;IAC5B,kBAAkB;IAClB","sourcesContent":[".MyTable {\n    width: 100%;\n    border-collapse: collapse; /* 이 속성은 테두리를 겹치게 합니다 */\n  }\n  \n.MyTable th, .MyTable td {\n    border: 1px solid black;\n    padding: 8px;\n    text-align: left;\n  }\n\n.MyTable th {\n    background-color: #BDD7EE;\n  }\n.MyTable .column-style {\n    background-color: #F8CBAD;\n  }\n\n.MyTable th:nth-child(1),\n.MyTable td:nth-child(1) {\n    /* width: 10%; 첫 번째 컬럼 너비 */\n    width: 150px; /* 첫 번째 컬럼 너비 */\n    }\n\n.MyTable th:nth-child(2),\n.MyTable td:nth-child(2) {\n    width: 450px; /* 두 번째 컬럼 너비 */\n    }\n\n.MyTable th:nth-child(3),\n.MyTable td:nth-child(3) {\n    /* width: 20%; 세 번째 컬럼 너비 */\n    width: 300px; /* 세 번째 컬럼 너비 */\n    }\n\n.MyTable th:nth-child(4),\n.MyTable td:nth-child(4) {\n    /* width: 20%; 네 번째 컬럼 너비 */\n    width: 300px; /* 네 번째 컬럼 너비 */\n    }\n\n.MyTable th:nth-child(5),\n.MyTable td:nth-child(5) {\n    /* width: 10%; 다섯 번째 컬럼 너비 */\n    /* width: 100px; */\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
