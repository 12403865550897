import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function Consultantlist() {
  const [open, setOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const uid = localStorage.getItem('uid');
  console.log(uid);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/consultant/getConsultEngagement/clientUid=${uid}`);
        console.log(response);

        // Assuming response.data contains an array of consultants
        const consultantIds = new Set(response.data.map(item => item.consultantId));

        const userdata = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await userdata.json();
        console.log(data);

        // Filter to keep only users with ids in the consultantIds set
        const filteredUsers = data.filter(user => consultantIds.has(user.id));
        setUserData(filteredUsers);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {userData ? (
                userData.map((consultantUser) => (
                  <Grid item xs={12} sm={6} md={4} key={consultantUser.id}>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        {consultantUser.username}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Email: {consultantUser.email}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Phone Number: {consultantUser.phone_number}
                      </Typography>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
