import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

const API_URL = process.env.REACT_APP_API_ORIGIN;

const defaultTheme = createTheme();

export default function Email() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.clear();
    navigate('/signin');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const user = {
      username: data.get('username'),
      confirmationCode: data.get('verifycode'),
      password: data.get('newpw'),
    };

    console.log(user);

    try {
      const response = await Axios.post(`${API_URL}/auth/resetPassword`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data);
      handleClickOpen();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            비밀번호 변경
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="ID 또는 이메일"
              type="username"
              id="username"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="verifycode"
              label="인증코드"
              type="verifycode"
              id="verifycode"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newpw"
              label="새로운 비밀번호"
              type="password"
              id="newpw"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              변경하기
            </Button>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"변경 완료"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>비밀번호 변경이 완료되었습니다.</p>
              <p>다시 로그인 해 주십시오.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
