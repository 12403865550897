import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function Consultantlist() {
  const [open, setOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    handleClose();
    console.log('1');
    try {
      console.log('start');
      const uid = localStorage.getItem('uid');
      console.log(uid);
      const response = await axios.get(`${API_URL}/auth/getUsernameByUid/uid=${uid}`);
      const username = response.data;
      await axios.delete(`${API_URL}/auth/user/${username}`);
      console.log('Account deleted successfully');
      // 로컬 스토리지 데이터 삭제
      localStorage.clear();

      // 로그인 페이지로 리다이렉트
      navigate('/SignIn');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {userData ? (
                  userData
                    .filter((user) => user.uid === localStorage.getItem('uid'))
                    .map((consultantUser) => (
                      <Grid item xs={12} sm={6} md={4} key={consultantUser.id}>
                        <Paper sx={{ p: 1 }}>
                          <Typography variant="h6" gutterBottom>
                            {consultantUser.username}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Email: {consultantUser.email}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Phone Number: {consultantUser.phone_number}
                          </Typography>
                          <Button color="error" onClick={handleClickOpen}>회원 탈퇴 하기</Button>
                          <Button onClick={() => navigate('/forgot')}>비밀번호 변경하기</Button>
                        </Paper>
                      </Grid>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"회원탈퇴 확인"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert severity="error">정말로 탈퇴하시겠습니까? 회원탈퇴는 되돌릴 수 없습니다.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
