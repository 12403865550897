import React from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export const updatestatus = async (useruid, stage, value) => {
    try {
        const curstatusResponse = await axios.get(`${API_URL}/auth/getStatus`, {
            params: {
                uid: useruid
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        const curstatus = curstatusResponse.data; // curstatus의 실제 값을 가져옵니다.
        
        let cur1000 = Math.floor(curstatus / 100000);
        let cur100 = Math.floor((curstatus % 100000) / 10000);
        let cur10 = Math.floor((curstatus % 10000) / 100);
        let cur1 = curstatus % 100;

        if (stage === 'fact'){
            cur1 = value;
        }
        else if (stage ==='insight'){
            cur10 = value;
        }
        else if (stage ==='strategy'){
            cur100 = value;
        }
        else if (stage ==='action'){
            cur1000 = value;
        }

        const final = cur1 + cur10 * 100 + cur100 * 10000 + cur1000 * 1000000;

        const response = await axios.post(`${API_URL}/auth/setStatus`, {
            uid: useruid,
            status: final,
        });
        console.log("userStatus updated successfully");
        console.log(response.data);
        return final;
    } catch (error) {
        // 요청이 실패하면 에러를 반환
        throw error;
    }
}

export const getStatusByStage = async (status, stage) => {
    let cur1000 = Math.floor(status / 100000);
    let cur100 = Math.floor((status % 100000) / 10000);
    let cur10 = Math.floor((status % 10000) / 100);
    let cur1 = status % 100;

    if (stage === 'fact'){
        return cur1;
    }
    else if (stage ==='insight'){
        return cur10;
    }
    else if (stage ==='strategy'){
        return cur100;
    }
    else if (stage ==='action'){
        return cur1000;
    }
}
