import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { TextField, Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Window from './Window/Window';
import { useNavigate } from 'react-router-dom';
import {updatestatus} from '../Utils';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const ValueGtmCanvas = React.forwardRef(({ questions, savedAnswers, insights, moveToNextStep},ref) => {

  const [editingIndex, setEditingIndex] = useState(-1); 
  const [editedText, setEditedText] = useState(''); 
  const theme = useTheme();
  const navigate = useNavigate();

  const [answerlist,setAnswerlist] = useState([]);
  const [answerStatus,setAnswerStatus] = useState(0);//모두 답변됐는지

  const updatedQuestions = useMemo(() => [...questions], [questions]);
  const [open,setOpen] = useState(false);
  const [showAlert,setShowAlert] = useState(false);
  const [openSnackbar,setOpenSnackbar] = useState(false);
  const [saveSpinner,setSaveSpinner] = useState(false);
  const documentRef = useRef(null);
  const [whoAnswers, setWhoAnswers] = useState([]);
  const [whatAnswers, setWhatAnswers] = useState([]);
  const [howAnswers, setHowAnswers] = useState([]);
  const [windowTitle, setWindowTitle] = useState('');
  const [uid,setUid] = useState('');
  const [popUpTableData, setPopUpTableData] = useState(null);
  const [showPopUpTable, setShowPopUpTable] = useState(false);


  useEffect(() => {
    const fetchUid = async () => {
      let BStype;
      if (localStorage.getItem('userType') === 'consultant')
      {
        const clientId = localStorage.getItem('clientId');
        const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        setUid(clientUidResponse.data);
      }
      else//client가 접속
      {
        setUid(localStorage.getItem('uid'));
      }
  }
    fetchUid();
  },[]);
  
  useEffect(() =>{
    //답변한 것만
    console.log("확인",updatedQuestions)
    // console.log("answerStatus",answerStatus)
    setAnswerStatus(0);

    const checkAnswerNumber = updatedQuestions.filter(q => q.answerText && q.answerText.trim() !== '').length;

    // const checkAnswerNumber = (updatedQuestions.filter(q => q.answerText)).length//답변 되어있는 개수 확인
  
    const submitdata = (updatedQuestions.filter(q => q.uid && q.answerText))// 수정한거 있으면 저장하기 위해
    setAnswerlist(submitdata)
    console.log("개수확인",checkAnswerNumber,updatedQuestions.length)
    
    if (checkAnswerNumber === updatedQuestions.length){
      setAnswerStatus(1);
      console.log("호출")
    }

    console.log("updatedQuestions set QuestionClassList",updatedQuestions)

  },[updatedQuestions,editingIndex]);

  useEffect(() => {

    //for each savedAnswer in savedAnswers, 
    //if the questionId matches, and questionType includes (lower) who, what, or how, add savedAnswer to the corresponding array
    const whoAnswers = [];
    const whatAnswers = [];
    const howAnswers = [];

    savedAnswers.forEach((savedAnswer) => {
      const question = insights.find(q => q.questionId === savedAnswer.questionId);
      if (question) {
        if (question.questionType.toLowerCase().includes('who') && question.questionType.toLowerCase().includes('밸류체인')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          whoAnswers.push(savedAnswer);
        } else if (question.questionType.toLowerCase().includes('what') && question.questionType.toLowerCase().includes('밸류체인')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          whatAnswers.push(savedAnswer);
        } else if (question.questionType.toLowerCase().includes('how') && question.questionType.toLowerCase().includes('밸류체인')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          howAnswers.push(savedAnswer);
        }
      }
    });
    setWhoAnswers(whoAnswers);
    setWhatAnswers(whatAnswers);
    setHowAnswers(howAnswers);

    console.log("whoAnswers", whoAnswers);
    console.log("whatAnswers", whatAnswers);
    console.log("howAnswers", howAnswers);

  }, []); // useEffect가 마운트 될 때 한 번만 호출

  const handleEdit = (index) => {
    setEditedText(questions[index].answerText);
    setEditingIndex(index); // 수정 모드로 변경
  };

  const transformQuestions = (questions) => {
    const tableMap = {};


    questions.forEach((question) => {
      if (question.questionClassList.length !== 2) {
        console.error(`Invalid question class list: ${question.questionClassList}`);
        return;
      }
      const [rowKey, colKey] = question.questionClassList;
      if (!tableMap[rowKey]) {
        tableMap[rowKey] = {};
      }
      if (!tableMap[rowKey][colKey]) {
        tableMap[rowKey][colKey] = [];
      }
      tableMap[rowKey][colKey].push(question);
    });
  
    return tableMap;
  };

  const transformedQuestions = transformQuestions(updatedQuestions);

  // inside rowHeaaders, drop the element 'TCR' and sort in descending order
  const rowHeaders = [...new Set(questions.flatMap(q => q.questionClassList[0]))].sort().reverse();
  const columnHeaders = [...new Set(questions.flatMap(q => q.questionClassList[1]))].sort().reverse();

  // function to return index of transformed question in the updatedQuestions array
  const getUpdatedQuestionIndex = (rowKey, colKey) => {
    const question = transformedQuestions[rowKey][colKey][0];
    return updatedQuestions.findIndex(q => q.questionId === question.questionId)
  };

  const sendUpdatedAnswer = async (updatedQuestion) => {
    try {
      const response = await axios.patch(`${API_URL}/api/answers/update`, {
        uid: uid,
        questionId: updatedQuestion.questionId,
        answerText: updatedQuestion.answerText
      });
      console.log("answer updated successfully")
      // 요청이 성공하면 응답 데이터를 반환
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  }

  const updateUserStatus = async (updateValue) => {
    try {
      const uid = localStorage.getItem('uid');
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: uid,
        status: updateValue,
      });
      console.log("userStatus updated successfully");
      localStorage.setItem('userStatus',updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const handleSave = (index) => {
    if (!editedText || editedText.trim() === '') {
      updatedQuestions[index].answerText = ''
    }
    else 
      updatedQuestions[index].answerText = editedText; // 수정된 내용 반영

    console.log("updatedQuestions",updatedQuestions[index]);

    sendUpdatedAnswer(updatedQuestions[index])
    
    setEditingIndex(-1); // 수정 모드 종료

  };

  // function to handle onclick of as-is issue button or insight button
  // when keyword is given (who, what, how) get the corresponding answers
  const handleButtonClick = (wwh, keyword) => {
    setWindowTitle(wwh + " " + keyword + "에 대한 질문과 답변 목록")
    wwh = wwh.toLowerCase();
    let answers = [];

    if (wwh === 'who') {
      answers = whoAnswers;
    } else if (wwh === 'what') {
      answers = whatAnswers;
    } else if (wwh === 'how') {
      answers = howAnswers;
    } else {
      console.log("Invalid keyword", wwh, keyword);
      setPopUpTableData([]);
      return;
    }

    let tableData;
    // 테이블 데이터 설정
    if (keyword === "AsIs") {
      tableData = answers.map((answer) => ({
        question: answer.question,
        answerText: answer.answerText,
        asIs: answer.asIs,
      }));
    }
    else {
      tableData = answers.map((answer) => ({
        question: answer.question,
        answerText: answer.answerText,
        insight: answer.insight,
      }));
    }
    setPopUpTableData(tableData);
    setShowPopUpTable(true);
  };



  const ConfirmDialog = ({onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
  
      setTimeout(() => {
        onConfirm(); // 실제 작업 수행
        setConfirming(false);
      }, 2000);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>제출하고 다음단계으로 이동하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
              답변이 최종 저장되고 다시 수정 할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
          {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const AlertDialog = ({onClose }) => {
    return (
      <Dialog open={showAlert} onClose={onClose}>
  
        <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" >
                답변 목록에 빈 답변이 있습니다.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const makePPTOnServer = async () => {
    try {
      const response = await axios.get(`${API_URL}/file/ppt/fillPpt/strategy_${uid}.pptx`);
      console.log(response.data);
      setOpen(false);
    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };
  
  const submitAndMoveNextStep = async () => {
    await saveAnswersToServer();
    makePPTOnServer()
    classifyResult();
    const userType = localStorage.getItem("userType");
    // Move to consultant mainpage
    if (userType == 'consultant')
      navigate('/ConsultantFile');

    // Move to customer mainpage
    else if (userType == 'client') {
      //updateUserStatus(29);
      const uid = localStorage.getItem('uid');
      updatestatus(uid,'strategy',9);
      navigate('/StrategyReport');
    }
    setOpen(false);
  }
  const classifyResult = () =>{
    console.log("이건 questions",questions)
    console.log("이건 updatedQuestion",updatedQuestions)
  }
    
  const hadleSaveAnswer = async() => { // 임시 저장버튼
    setSaveSpinner(true)
    await saveAnswersToServer();
    setTimeout(() => {
      setSaveSpinner(false)
      setOpenSnackbar(true)

    }, 1000);
  }

  const handleSubmit = () => { //제출 버튼
    if (answerStatus === 1) {
      setOpen(true)
    }
    else 
    {
      setShowAlert(true)
    }
  };

  const saveAnswersToServer = async () => { //답변 저장 api호출
    try {
      const response = await axios.post(`${API_URL}/api/answers/submit`, answerlist );
      console.log(response.data); 


    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  React.useImperativeHandle(ref, () => ({
    submitDocument: () => {
      handleSubmit();
    },
  }));
  
  return (
    <Paper ref={documentRef} sx={{display: 'flex', flexDirection: 'column' }}>
      <TableContainer>
      <Table>
      <TableHead>
      <TableRow>
              <TableCell colSpan={columnHeaders.length + 1} style={{ textAlign: "center", backgroundColor: "#e2e4ed", color: 'black', fontWeight: 'bold', fontSize: '18px', color: '#333' }}>GTM 전략의 핵심요소</TableCell>
            </TableRow>
        <TableRow>
          <TableCell style={{ backgroundColor: '#2f6eba', color: 'white', textAlign: 'center', fontWeight: 'bold'  }}>GTM Canvas</TableCell>
          {columnHeaders.map((header) => (
            <TableCell key={header} style={{backgroundColor: '#2f6eba', color: 'yellow', textAlign: 'center', fontWeight: 'bold' }}>{header}
            <br></br>
            <Button
              style={{ backgroundColor: "#bbbfc3", color: "black", margin: '1px' }}
              onClick={() => handleButtonClick(header, 'AsIs')}>
              AS-IS 이슈
            </Button>
            <Button
              style={{ backgroundColor: "#f9d77e", color: "black", }}
              onClick={() => handleButtonClick(header, 'insight')}>
              INSIGHT
            </Button>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowHeaders.map((rowHeader) => (
          <TableRow key={rowHeader}>
            <TableCell style={{ backgroundColor: '#2f6eba', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{rowHeader}</TableCell>
            {columnHeaders.map((colHeader) => (
              <TableCell key={`${rowHeader}-${colHeader}`}>
                {/* Render question items or an indication of absence */}
                {transformedQuestions[rowHeader][colHeader]?.map((item, index) => (
                  <div key={index} style={{ fontWeight: 'bold', fontSize: '14px', color: '#333', marginBottom: '10px' }}>
                    {item.question}
                  <TableCell 
                onMouseEnter={() => handleMouseEnter(getUpdatedQuestionIndex(rowHeader, colHeader))}
                onMouseLeave={handleMouseLeave}
                onDoubleClick={() => handleEdit(getUpdatedQuestionIndex(rowHeader, colHeader))}
                style={{
                  width: '300px',
                  cursor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? 'pointer' : 'auto',
              backgroundColor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? theme.palette.action.hover : 'inherit',
            }}
                >
                  {editingIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? ( // 수정 모드인 경우
                    <TextField
                    multiline // 여러 줄의 텍스트 입력
                    rows={10} // 텍스트 필드의 행 수
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onBlur={() => handleSave(getUpdatedQuestionIndex(rowHeader, colHeader))}
                    inputProps={{ style: { fontSize: '14px' } }}
                  />
                  ) : (
                    <div
                    // onDoubleClick={() => handleEdit(index)}>
                    >
                      {item.answerText}
                    </div> // 텍스트 클릭 시 수정 모드로 전환
                  )}
                </TableCell>
                  </div>
                )) || 'N/A'}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
      </TableContainer>
      <Fab 
      varient ="extended"
      onClick ={hadleSaveAnswer}
      sx={{
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[600],
        },
        position: 'fixed',
        width: 100,
        bottom: 30,
        right: 150,
        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
        boxShadow: '0, 0, 0, 0.5',
      }} 
      disabled={saveSpinner} // 작업이 진행 중일 때 버튼을 비활성화합니다.
      >
        {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>} {/* 스피너 또는 텍스트 표시 */}
      </Fab>
      <Fab color="secondary"
      varient ="extended"
      onClick ={handleSubmit}
      sx={{
        position: 'fixed',
        width: 100,
        bottom: 30,
        right: 30,
        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
        boxShadow: '0, 0, 0, 0.5',
      }} >
        <span>제출</span>
        <RightArrow />
      </Fab>
      <ConfirmDialog onClose={() => setOpen(false)} onConfirm={submitAndMoveNextStep}/>
      <AlertDialog onClose={() => setShowAlert(false)}/>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'center',
        }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          답변이 저장되었습니다!
        </Alert>
      </Snackbar>
      {showPopUpTable ? 
      <Window
              id="react-window2"
              // window is initially closed
              initialZIndex={0}
              height={window.innerHeight/2}
              width={window.innerWidth/2}
              left={window.innerWidth/3}
              top={window.innerHeight/3}
              resizable={true}
              titleBar={{
                // icon: "R",
                title: `${windowTitle}`,
                buttons: { minimize: true, maximize: false, close() { setShowPopUpTable(false); } }
              }}
              style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}
            >
              <div>
                {popUpTableData ?
                  <table className="MyTable">
                    <thead>
                      <tr>
                        <th>질문</th>
                        <th>답변</th>
                        {windowTitle.includes("AsIs") ? <th>AS_IS</th> : <th>Insight</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {popUpTableData ? popUpTableData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.question}</td>
                          <td>{item.answerText}</td>
                          {windowTitle.includes("AsIs") ? <td>{item.asIs}</td> : <td>{item.insight}</td>}
                        </tr>
                      )) : <tr><td colSpan="4">보고자하는 답변의 버튼을 눌러주세요</td></tr>}
                    </tbody>
                  </table>
                  : <div>테이블의 버튼을 눌러 이전 답변들을 확인 가능합니다.</div>}
              </div>
            </Window>
            : null}
    </Paper>
    
  );
});

export default ValueGtmCanvas;
