import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const StrategyDetail = () => {
    const uid = localStorage.getItem('uid');
    const [open, setOpen] = useState(true);
    const [status, setStatus] = useState('-1');
    const [businessType, setBusinessType] = useState('');
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const user = {
        uid: uid
    };

    useEffect(() => {
        // fetch to get business type and status (multiple fetch, body contatins user)
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${API_URL}/auth/getStatus`, {
                    params: {
                        uid: uid
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const businessTypeResponse = await Axios.get(`${API_URL}/auth/getBusinessType`, {
                    params: {
                        uid: uid
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.data;
                const businessType = await businessTypeResponse.data;
                console.log('status',data);
                console.log('businessType',businessType);
                setStatus(data.status);
                setBusinessType(businessType);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        // local storage에 저장
        localStorage.setItem('businessType', businessType);
    }, [uid, businessType]);

    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 상단에 FACT 단계 제목 */}
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left">
                                    STRATEGY 단계
                                </Typography>
                                <Divider />
                            </Grid>

                            {/* 가로로 반으로 나뉘어진 섹션 */}
                            <Grid item xs={6}>
                                {/* 첫 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    창업자가 사업 런칭 후 1~2년 동안 공략해야 할 SOM 시장에서의 GTM(Go-To-Market)전략을 개발합니다.
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    GTM 전략은 Who(고객)-What(제품/서비스)-How(유통/마케팅)를 핵심 축으로 사업유형 별로 차별화된 GTM 캔버스를 활용하여 전략 개발합니다.
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                {/* 두 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>GOAL</strong><br />
                                    ● SOM 시장 구체화<br />
                                    ● GTM 캔버스 개발<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>STEPS</strong><br />
                                    ● SOM 시장 목표 고객에 대한 페르소나 개발<br />
                                    ● 제품 / 서비스 차별화, 유통 / 마케팅 방안<br />
                                    ● 사업 유형별 GTM 캔버스 개발<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>OUTCOMES</strong><br />
                                    ● SOM 시장 대상 GTM 전략 캔버스<br />
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 버튼 추가 */}
                        <Grid item xs={12} style={{ marginTop: '24px', textAlign: 'center' }}>
                            <button onClick={() => navigate('/StrategyGTM')} style={{ fontSize: '12px', padding: '8px 36px' }}>
                                GTM 캔버스로 이동
                            </button>
                        </Grid>

                        {/* 하단 설명 */}
                        <Grid item xs={12} style={{ marginTop: '48px' }}>
                            <Typography variant="body2" align="left">
                                <strong>GTM (Go-To-Market):</strong> 제품이나 서비스를 시장에 성공적으로 출시하고 판매하기 위한 전체적인 전략을 일컫는 말
                            </Typography>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default StrategyDetail;
