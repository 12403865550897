import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

const API_URL = process.env.REACT_APP_API_ORIGIN;

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
  export default function Email() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location
    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
  
      const user = {
        username: data.get('username'),
      };
  
      console.log(user);
  
      try {
        // Replace 'YOUR_SPRINGBOOT_API_ENDPOINT' with the actual endpoint of your Spring Boot API
        const response = await Axios.post(`${API_URL}/auth/forgotPassword`, user,{
          headers:{
            'Content-Type':'application/json',
          },
        });
  
        // Handle the response as needed
        console.log(response.data);
        navigate('/Reset');
      } catch (error) {
        // Handle errors
        console.error('Error submitting form:', error);
      }
    };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            비밀번호 찾기 / 변경
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="ID 또는 이메일"
              type="username"
              id="username"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
